import React from "react";
import { DragPreviewImage, useDrag } from "react-dnd";

// Images
import previewGoldCoinImg from "../../assets/images/dollar_coin-preview.png";
import previewSilverCoinImg from "../../assets/images/dollar_coin_silver-preview.png";
import previewBronzeCoinImg from "../../assets/images/dollar_coin_bronze-preview.png";

// Utils
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection"

let sentCount = 0

export default function DraggableCoin({ playerId, isObjectDragging, draggingObject, dragCoinIndex, containerId, value, isLastCoin, name, alias, index, dispatch, regionIndex }) {
	const [{ isDragging }, drag, preview] = useDrag({
		item: {
			type: items.coin,
			value: value,
			index: index,
			containerId: containerId
		},
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (item, monitor) => {
			if (monitor.didDrop()) {
				let droppedItem = monitor.getDropResult();

				if (droppedItem !== null && droppedItem.success) {
					const obj = {
						name: name,
						regionIndex: regionIndex,
						alias: alias,
						index: index,
						value: item.value
					}
					RealtimeConnection.removeCoin(obj)
					//dispatch({ type: "removeCoin", regionIndex: regionIndex, id: name, alias: alias, index: index, value: value });
				}
			}
		},
	});

	let canPlayerDrag = drag;

	if (isDragging) {
		if (window.playerId !== playerId && sentCount === 0) {
			sentCount++;
			const obj = {
				name: name,
				regionIndex: regionIndex,
				alias: alias,
				coinIndex: index,
				isDragging: true
			}
			RealtimeConnection.coinDragging(obj)
		}
	}else if (isObjectDragging && draggingObject === "COIN" && index === dragCoinIndex) {
		canPlayerDrag = null;
	}else{
		if (sentCount > 0){
			sentCount = 0;

			const obj = {
				name: name,
				regionIndex: regionIndex,
				alias: alias,
				coinIndex: index,
				isDragging: false
			}
			RealtimeConnection.coinDragging(obj)
		}
	}

	let previewImg = previewGoldCoinImg;
	let coinClass = isLastCoin ? "last-coin " : "not-last-coin ";
	switch (value) {
		case 10:
			coinClass += "gold";
			previewImg = previewGoldCoinImg;
			break;
		case 1:
			coinClass += "silver";
			previewImg = previewSilverCoinImg;
			break;
		case 0.5:
			coinClass += "bronze";
			previewImg = previewBronzeCoinImg;
			break;
		default:
			break;
	}

	const style = {
		opacity: (isDragging || (isObjectDragging && draggingObject === "COIN" && index === dragCoinIndex)) ? 0.5 : 1
	}
	return (
		<>
			<DragPreviewImage connect={ preview } src={previewImg} />
			<div style={style} className={["coin", coinClass].join(" ")} ref={canPlayerDrag} />
		</>
	);
}
