import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

// Components
import DraggableAvatar from "../CenterComponents/DraggableAvatar";

export default function ParkContainer({ avatars, canEdit }) {
	const [avatarList, setAvatarList] = useState([]);
	const [placeholderList, setPlaceholderList] = useState([])
	const [{ isOver }, drop] = useDrop({
		accept: [items.green], //items.grey
		collect: (monitor) => ({
			isOver: monitor.isOver(),
		}),
		drop: (item) => {
			console.log(item)
			if (item.color === "green" && avatars < 5) {
				const obj = {
					alias: "park",
					color: item.color,
				};
				RealtimeConnection.addAvatar(obj);
				return { success: true };
			}
		},
	});

	useEffect(() => {
		//let listAvatars = avatars ? avatars.filter((x) => x) : [];
		if (avatars) {
			const listAvatars = Array.from({length: avatars}).fill("green")
			setAvatarList(listAvatars);
			if (5 - listAvatars.length > 0) {
				setPlaceholderList([...Array(5 - listAvatars.length).keys()])
			}else{
				setPlaceholderList([])
			}
		}else{
			setAvatarList([])
			setPlaceholderList([...Array(5).keys()])
		}
	}, [avatars]);

	const canDrop = canEdit ? drop : null;
	return (
		<div
			ref={canDrop}
			className="park-field-wrapper"
			style={isOver ? { opacity: "0.7" } : { opacity: "1" }}
		>
			{avatarList.map((e, index) => (
				<DraggableAvatar
					canEdit={canEdit}
					key={index}
					index={index}
					color={e}
					park={true}
				/>
			))}
			{placeholderList.map((_, index) => (
				<div key={ index } className="avatar-pic" style={{ height: 22 }}></div>
			))}
		</div>
	);
}
