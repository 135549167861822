import React from "react";
import { Col } from "reactstrap";
import { useDrop } from "react-dnd";

// Components
import DroppableContainer from "../draggable/DroppableContainer";

// Utils
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

// Images
import arrow from "../../assets/SVG/Asset 10.svg";
import Translate from "../../utils/Translate";

export default function LeftBlock({
	canEdit,
	cashCoinsCount,
	playerId,
	isObjectDragging,
	draggingObject,
	coinIndex,
	id,
	name,
	hasContainer,
	dispatch,
	alias,
	coins,
	coinsCount,
}) {
	const [{ isOver }, drop] = useDrop({
		accept: items.container,
		collect: (monitor) => ({
			isOver: monitor.isOver(),
		}),
		drop: (item) => {
			const obj = {
				alias: alias,
				name: id,
				coins: item.coins ? item.coins : [],
			};
			RealtimeConnection.addContainer(obj);
			return { success: true };
		},
	});

	const canDrop = canEdit ? drop : null;
	return (
		<Col className="side-bar-block p-0" key={name} id={name}>
			<div className="blueish">
				<DroppableContainer
					canEdit={canEdit}
					cashCoinsCount={cashCoinsCount}
					//depreciation={ true }
					containerDrop={canDrop}
					containerIsOver={isOver}
					hasContainer={hasContainer}
					name={id}
					fieldIndex={id}
					alias={alias}
					dispatch={dispatch}
					coins={coins}
					coinsCount={coinsCount}
					playerId={playerId}
					isObjectDragging={isObjectDragging}
					draggingObject={draggingObject}
					dragCoinIndex={coinIndex}
				/>
				<p className="sm-header text-white text-center p-1">
					<Translate alias="Board" word={name} />
				</p>
			</div>

			<Col className="flex-grow-0 p-0">
				<img
					src={arrow}
					alt="arrow"
					className="mt-2 ml-2"
					style={{ height: "5vh" }}
				/>
			</Col>
		</Col>
	);
}
