import React, { createContext, useReducer } from "react";
import { initialBoardState } from "../utils/init-state";
import { initialTeamTableState } from "../utils/init-team-table-state";
import { initialLabotMarketState } from "../utils/init-labor-market-state";
import sessionState from "../utils/session-init-state";
import PlanningSheet from "../utils/planning-sheet";
import AnnualReport from "../utils/annual-report-init-state";
import produce from "immer";

const initialState = {
	Board: initialBoardState,
	TeamTable: initialTeamTableState,
	LaborMarket: initialLabotMarketState,
	Session: sessionState,
	planningSheet: PlanningSheet,
	annualReport: AnnualReport,
};

export const StateContext = createContext();
export const DispatchContext = createContext();

const refactorCoins = (coins) => {
	let newCoins = [];

	if (coins.length > 0) {
		let coinsValue = coins.reduce((total, num) => {
			return parseFloat(total) + parseFloat(num);
		});

		while (coinsValue > 0) {
			if (coinsValue >= 10) {
				newCoins.push(10);
				coinsValue -= 10;
			} else if (coinsValue >= 1) {
				newCoins.push(1);
				coinsValue -= 1;
			} else {
				newCoins.push(0.5);
				coinsValue -= 0.5;
			}
		}
	}

	return newCoins;
};

function refactorSum(coins) {
	var coinsList = [];
	while (coins > 0) {
		if (coins >= 10) {
			coinsList.push(10);
			coins -= 10;
		} else if (coins >= 1) {
			coinsList.push(1);
			coins -= 1;
		} else {
			coinsList.push(0.5);
			coins -= 0.5;
		}
	}
	return coinsList;
}
function reducer(draft, action) {
	switch (action.type) {
		case "RestoreTeamTable":
			draft.TeamTable = action.data;
			return;
		case "RestoreBoard":
			draft.Board = action.data;
			return;
		case "RestoreMsgs":
			draft.Session.messages = action.data;
			return;
		case "RestoreForm":
			if (action.data) {
				if (action.data.planningSheet) {
					draft.planningSheet =
						action.data.planningSheet.planningSheet;
				}
				if (action.data.annualReport) {
					draft.annualReport = action.data.annualReport.annualReport;
				}
			}
			return;
		case "changeFormYear":
			if (action.data.form) {
				draft[action.data.type] = action.data.form[action.data.type];
			} else {
				draft.planningSheet = initialState.planningSheet;
				draft.annualReport = initialState.annualReport;
			}
			draft.Session.formSelectedYear = action.data.year;

			return;
		case "changeYear":
			draft.Session.formSelectedYear = action.year;
			draft.Session.year = action.year;
			return;
		case "allowTimeAllocation":
			draft.Session.allowTimeAllocation = action.status;
			return;
		case "RestoreLaborMarket":
			draft.LaborMarket = action.data;
			return;
		case "LaborMarket":
			draft.LaborMarket[action.data.region][action.data.key] =
				action.data.value;
			return;
		case "UpdateTeamTable":
			draft.TeamTable[action.data.type] = action.data.value;
			return;
		case "UpdateRegionCustomerData":
			draft.Board.regions[action.data.region].customer[action.data.key] =
				action.data.value;
			return;
		case "UpdateRegionMotivationValue":
			draft.Board.regions[action.data.region][action.data.key] =
				action.data.value;
			return;
		case "DecreaseCash":
			let containers = draft.Board.cash.map((x) => {
				let cashCoins = 0;
				cashCoins = x.coins.reduce((a, b) => a + b, 0);
				return cashCoins;
			});

			let index = 0;
			let count = action.data.value;
			while (count > 0) {
				if (containers[index] >= count) {
					let coinsList = draft.Board.cash[index].coins;
					coinsList = refactorSum(containers[index] - count);
					draft.Board.cash[index].coins = coinsList;
					count = 0;
				} else {
					count -= containers[index];
					draft.Board.cash[index].coins = [];
				}
				index++;
			}

			return;
		case "IncreaseCash":
			let containerIndex = 0;
			let valueCount = action.data.value;
			let firstContainer = draft.Board.cash[containerIndex];
			let coinsList = draft.Board.cash[containerIndex].coins;

			if (firstContainer.hasContainer) {
				var coins =
					firstContainer.coins.reduce((a, b) => a + b, 0) +
					action.data.value;
				coinsList = refactorSum(coins);
				while (valueCount > 0) {
					if (coinsList.length < 20) {
						draft.Board.cash[containerIndex].coins = coinsList;
						valueCount = 0;
					}
					containerIndex++;
				}
			}

			return;
		case "DecreaseTeamTable":
			console.log(action.data.value);
			draft.TeamTable[action.data.type] -= action.data.value;
			return;
		case "IncreaseTeamTable":
			draft.TeamTable[action.data.type] += action.data.value;
			return;
		case "toggleChat":
			draft.Session.isChatOpened = action.isOpened;
			return;
		case "messagesList":
			draft.Session.messages = [
				...draft.Session.messages,
				action.message,
			];
			return;
		case "addEquity":
			draft.Board.equity = action.value;
			return;
		case "planningSheet":
			draft.Session["formStatus"].planningSheet = action.value;
			return;
		case "updatePlanningSheet":
			if (action.data.status === "dropDown") {
				draft.planningSheet[action.data.alias]["fields"][action.data.parentIndex]["fields"][action.data.itemIndex]["dropDown"][action.data.pre].value = !action.data.value;
				draft.planningSheet[action.data.alias]["fields"][action.data.parentIndex]["fields"][action.data.itemIndex]["dropDown"][action.data.field].value = action.data.value;
			} else {
				if (action.data.fieldIndex !== undefined && action.data.nestedIndex === undefined) {
					draft.planningSheet[action.data.alias]["fields"][action.data.parentIndex]["fields"][action.data.fieldIndex].value = action.data.value;
				}
				if (action.data.fieldIndex === undefined && action.data.nestedIndex === undefined) {
					draft.planningSheet[action.data.alias]["fields"][action.data.parentIndex].sum = action.data.value;
					draft.planningSheet[action.data.alias].totalSum += action.data.value;
					draft.planningSheet["fifth"].totalSum += action.data.value;
				}
				if (action.data.fieldIndex !== undefined && action.data.nestedIndex !== undefined) {
					draft.planningSheet[action.data.alias]["fields"][action.data.parentIndex]["fields"][action.data.fieldIndex]["fields"][action.data.nestedIndex].value = action.data.value;
				}
			}

			return;
		case "annualReport":
			draft.Session["formStatus"].annualReport = action.value;
			return;
		case "updateAnnualReport":
			draft.annualReport[action.data.groupName][action.data.field] =
				action.data.value;
			return;
		case "addContainer":
			if (action.regionIndex !== undefined) {
				draft.Board.regions[action.regionIndex][action.alias][
					action.id
				].hasContainer = true;
				draft.Board.regions[action.regionIndex][action.alias][
					action.id
				].coins = action.coins;
			} else {
				draft.Board[action.alias][action.id].hasContainer = true;
				draft.Board[action.alias][action.id].coins = action.coins;
			}
			return;
		case "removeContainer":
			if (action.regionIndex !== undefined) {
				draft.Board.regions[action.regionIndex][action.alias][
					action.id
				].hasContainer = false;
				draft.Board.regions[action.regionIndex][action.alias][
					action.id
				].coins = action.coins;
				draft.Board.regions[action.regionIndex][action.alias][
					action.id
				].isObjectDragging = false;
				draft.Board.regions[action.regionIndex][action.alias][
					action.id
				].draggingObject = "";
				draft.Board.regions[action.regionIndex][action.alias][
					action.id
				].playerId = null;
			} else {
				draft.Board[action.alias][action.id].hasContainer = false;
				draft.Board[action.alias][action.id].coins = action.coins;
				draft.Board[action.alias][action.id].isObjectDragging = false;
				draft.Board[action.alias][action.id].draggingObject = "";
				draft.Board[action.alias][action.id].playerId = null;
			}
			return;
		case "deleteContainer":
			let sum = action.coins.reduce((a, b) => a + b, 0);
			draft.TeamTable.coins += sum;
			return;
		case "addCoin":
			if (action.regionIndex !== undefined) {
				let coinsList = [
					...draft.Board.regions[action.regionIndex][action.alias][
						action.id
					].coins,
				];
				coinsList.push(action.value);
				coinsList = refactorCoins(coinsList);

				draft.Board.regions[action.regionIndex][action.alias][
					action.id
				].coins = coinsList;
			} else {
				let coinsList = [...draft.Board[action.alias][action.id].coins];
				coinsList.push(action.value);
				coinsList = refactorCoins(coinsList);

				draft.Board[action.alias][action.id].coins = coinsList;
			}
			//draft.TeamTable.coins -= action.value;
			return;
		case "removeCoin":
			if (action.regionIndex !== undefined) {
				draft.Board.regions[action.regionIndex][action.alias][
					action.id
				].coins.splice(action.index, 1);
			} else {
				draft.Board[action.alias][action.id].coins.splice(
					action.index,
					1
				);
			}
			//draft.TeamTable.coins += action.value;
			return;
		case "addToken":
			draft.Board.liabilty += action.value;
			//draft.TeamTable.tokens -= action.value
			return;
		case "deleteToken":
			draft.Board.liabilty -= action.value;
			//draft.TeamTable.tokens += action.value
			return;
		case "addTime":
			const arr =
				draft.Board.regions[action.regionIndex].time[action.alias];
			let TimeItem =
				draft.Board.regions[action.regionIndex].time[action.alias][
					action.timeIndex
				];
			let total = 0;
			for (var i in arr) {
				total += arr[i].value;
			}

			if (total <= 90 && TimeItem.value <= 90) {
				TimeItem.value += 10;
			}
			return;
		case "subtractTime":
			if (
				draft.Board.regions[action.regionIndex].time[action.alias][
					action.timeIndex
				].value > 0
			) {
				draft.Board.regions[action.regionIndex].time[action.alias][
					action.timeIndex
				].value -= 10;
			}
			return;
		case "addAvatar":
			if (action.alias === "park") {
				draft.TeamTable.park += 1;
			} else {
				if (action.color === "white") {
					let el = draft.Board.regions[action.regionIndex].green;
					if (el.count < el.max) {
						el.white += 1;
						el.count += 1;
						el.whiteList.push({
							hiredYear: action.year,
							lastRegion: action.lastRegion,
						});
					}
				} else {
					let el =
						draft.Board.regions[action.regionIndex][action.color];
					if (el.count < el.max) {
						el.count += 1;
						el.list.push({
							hiredYear: action.year,
							lastRegion: action.lastRegion,
						});
					}
				}
			}
			return;
		case "deleteAvatar":
			if (action.alias === "park") {
				draft.TeamTable.park -= 1;
			} else {
				if (action.color === "white") {
					let el = draft.Board.regions[action.regionIndex]["green"];
					el.count -= 1;
					el.white -= 1;
					el.whiteList.splice(action.index, 1);
				} else {
					let el =
						draft.Board.regions[action.regionIndex][action.color];
					el.count -= 1;
					el.list.splice(action.index, 1);
				}
			}
			return;
		case "addPin":
			draft.Board.regions[action.regionIndex].pins[
				action.pinNumber
			].hasPin = true;
			return;
		case "deletePin":
			draft.Board.regions[action.regionIndex].pins[
				action.pinNumber
			].hasPin = false;
			return;

		case "addFiles":
			draft.Session.files.push(action.files);
			return;
		case "removeFiles":
			let list = draft.Session.files.filter(
				(file) => file._id !== action.files._id
			);
			draft.Session.files = list;
			return;
		case "files":
			draft.Session.files = action.files;
			return;
		case "addLabel":
			draft.Board.regions[action.regionIndex][action.alias][
				action.id
			].status = action.labelType;
			return;
		case "deleteLabel":
			draft.Board.regions[action.regionIndex][action.alias][
				action.id
			].status = "";
			return;

		case "locationStatus":
			let el = draft.Board.regions[action.regionIndex]["containers"][0];
			if (action.status === "Purchase") {
				el.rented = false;
			} else {
				el.rented = true;
				el.coins = [];
			}

			return;
		case "containerDragging":
			if (action.regionIndex !== undefined) {
				draft.Board.regions[action.regionIndex][action.alias][
					action.id
				].isObjectDragging = action.isDragging ? true : false;
				draft.Board.regions[action.regionIndex][action.alias][
					action.id
				].draggingObject = "CONTAINER";
				draft.Board.regions[action.regionIndex][action.alias][
					action.id
				].playerId = action.isDragging ? action.playerId : null;
			} else {
				draft.Board[action.alias][action.id].isObjectDragging =
					action.isDragging ? true : false;
				draft.Board[action.alias][action.id].draggingObject =
					"CONTAINER";
				draft.Board[action.alias][action.id].playerId =
					action.isDragging ? action.playerId : null;
			}
			return;
		case "coinDragging":
			if (action.regionIndex !== undefined) {
				draft.Board.regions[action.regionIndex][action.alias][
					action.id
				].isObjectDragging = action.isDragging ? true : false;
				draft.Board.regions[action.regionIndex][action.alias][
					action.id
				].draggingObject = "COIN";
				draft.Board.regions[action.regionIndex][action.alias][
					action.id
				].playerId = action.isDragging ? action.playerId : null;
				draft.Board.regions[action.regionIndex][action.alias][
					action.id
				].coinIndex = action.coinIndex;
			} else {
				draft.Board[action.alias][action.id].isObjectDragging =
					action.isDragging ? true : false;
				draft.Board[action.alias][action.id].draggingObject = "COIN";
				draft.Board[action.alias][action.id].playerId =
					action.isDragging ? action.playerId : null;
				draft.Board[action.alias][action.id].coinIndex =
					action.coinIndex;
			}
			return;

		case "sessionActiveEventId":
			draft.Session.currentEventId = action.data.eventId;
			return;
		default:
			return;
	}
}

const curriedReducer = produce(reducer);
export const StateProvider = ({ children }) => {
	const [state, dispatch] = useReducer(curriedReducer, initialState);

	return (
		<DispatchContext.Provider value={dispatch}>
			<StateContext.Provider value={state}>
				{children}
			</StateContext.Provider>
		</DispatchContext.Provider>
	);
};
