import React from "react";
import { Table, FormControl } from "react-bootstrap";
import InputNumber from "rc-input-number";
import RealtimeConnection from "../../../utils/RealtimeConnection";
import Translate from "../../../utils/Translate";

class IncomeStatement extends React.Component {
	handleChange(field, value) {
		if (this.props.canEdit) {
			RealtimeConnection.changeAnnualReport({
				year: this.props.year,
				groupName: "incomeStatement",
				field: field,
				value: value,
			});
		}
	}

	render() {
		let grossProfit = 0;
		if (!isNaN(parseFloat(this.props.incomeStatement.salesRevenue)))
			grossProfit += parseFloat(this.props.incomeStatement.salesRevenue)
			
		if (!isNaN(parseFloat(this.props.incomeStatement.Salary)))
			grossProfit -= parseFloat(this.props.incomeStatement.Salary)

		let totalOverhead = 0;
		if (!isNaN(parseFloat(this.props.incomeStatement.SignOn)))
			totalOverhead += parseFloat(this.props.incomeStatement.SignOn)
			
		if (!isNaN(parseFloat(this.props.incomeStatement.Training)))
			totalOverhead += parseFloat(this.props.incomeStatement.Training)
			
		if (!isNaN(parseFloat(this.props.incomeStatement.Severance)))
			totalOverhead += parseFloat(this.props.incomeStatement.Severance)
			
		if (!isNaN(parseFloat(this.props.incomeStatement.MarketEntry)))
			totalOverhead += parseFloat(this.props.incomeStatement.MarketEntry)
			
		if (!isNaN(parseFloat(this.props.incomeStatement.Marketing)))
			totalOverhead += parseFloat(this.props.incomeStatement.Marketing)
			
		if (!isNaN(parseFloat(this.props.incomeStatement.Lease)))
			totalOverhead += parseFloat(this.props.incomeStatement.Lease)
		
		if (!isNaN(parseFloat(this.props.incomeStatement.Depreciation)))
			totalOverhead += parseFloat(this.props.incomeStatement.Depreciation)
			
		if (!isNaN(parseFloat(this.props.incomeStatement.Overheads)))
			totalOverhead += parseFloat(this.props.incomeStatement.Overheads)

		const ebit = grossProfit - totalOverhead;

		let profitBeforeTax = ebit;

		if (!isNaN(parseFloat(this.props.incomeStatement.Interest)))
			profitBeforeTax -= parseFloat(this.props.incomeStatement.Interest)

		let profitBeforeTaxAdj = profitBeforeTax;

		if (!isNaN(parseFloat(this.props.incomeStatement.Bonus)))
			profitBeforeTaxAdj -= parseFloat(this.props.incomeStatement.Bonus)

		let taxableProfit = profitBeforeTaxAdj;
		if (!isNaN(parseFloat(this.props.incomeStatement.LossCarriedForward))) {
			if (parseFloat(this.props.incomeStatement.LossCarriedForward) > 0) {
				taxableProfit -= parseFloat(this.props.incomeStatement.LossCarriedForward)
			}else{
				taxableProfit += parseFloat(this.props.incomeStatement.LossCarriedForward)
			}
		}

		let tax = taxableProfit * (25/100)
		tax = Math.round(tax * -2) / -2

		let profitAfterTax = profitBeforeTaxAdj - tax;
		profitAfterTax = Math.round(profitAfterTax * -2) / -2
		/*if (!isNaN((Math.round(taxableProfit * 2) / 2) * 0.25))
			profitAfterTax -= (Math.round(taxableProfit * 2) / 2) * 0.25*/

		console.log("profitAfterTax = " + profitAfterTax)
		return (
			<Table bordered>
				<tbody>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Sales revenue" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.incomeStatement.salesRevenue}
								onChange={(value) =>
									this.handleChange("salesRevenue", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Salary" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.incomeStatement.Salary}
								onChange={(value) =>
									this.handleChange("Salary", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Gross profit" />
							</div>
						</td>
						<td>
							<FormControl
								aria-label="Small"
								size="sm"
								value={grossProfit}
								disabled
							/>
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Sign On" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.incomeStatement.SignOn}
								onChange={(value) =>
									this.handleChange("SignOn", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Training" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.incomeStatement.Training}
								onChange={(value) =>
									this.handleChange("Training", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Severance" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.incomeStatement.Severance}
								onChange={(value) =>
									this.handleChange("Severance", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Market Entry" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.incomeStatement.MarketEntry}
								onChange={(value) =>
									this.handleChange("MarketEntry", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Marketing" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.incomeStatement.Marketing}
								onChange={(value) =>
									this.handleChange("Marketing", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>

					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Lease" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.incomeStatement.Lease}
								onChange={(value) =>
									this.handleChange("Lease", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Depreciation" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.incomeStatement.Depreciation}
								onChange={(value) =>
									this.handleChange("Depreciation", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Overheads" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.incomeStatement.Overheads}
								onChange={(value) =>
									this.handleChange("Overheads", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>

					<tr className="autofillFieldBackground">
						<td>
							<div className="bold">
								<Translate
									alias="Forms"
									word="Total Overhead"
								/>
							</div>
						</td>
						<td>
							<FormControl
								aria-label="Small"
								size="sm"
								value={totalOverhead}
								disabled
							/>
						</td>
					</tr>

					<tr className="autofillFieldBackground">
						<td>
							<div className="bold">
								<Translate alias="Forms" word="EBIT" />
							</div>
						</td>
						<td>
							<FormControl
								aria-label="Small"
								size="sm"
								value={ebit}
								disabled
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Interest" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.incomeStatement.Interest}
								onChange={(value) =>
									this.handleChange("Interest", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold">
								<Translate
									alias="Forms"
									word="Earnings before taxes"
								/>
							</div>
						</td>
						<td>
							<FormControl
								aria-label="Small"
								size="sm"
								value={profitBeforeTax}
								disabled
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Bonus" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.incomeStatement.Bonus}
								onChange={(value) =>
									this.handleChange("Bonus", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold">
								<Translate
									alias="Forms"
									word="Earnings before taxes adj."
								/>
							</div>
						</td>
						<td>
							<FormControl
								aria-label="Small"
								size="sm"
								value={profitBeforeTaxAdj}
								disabled
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate
									alias="Forms"
									word="Loss carried forward"
								/>
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={
									this.props.incomeStatement
										.LossCarriedForward
								}
								onChange={(value) =>
									this.handleChange(
										"LossCarriedForward",
										value
									)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold">
								<Translate
									alias="Forms"
									word="Taxable Profit"
								/>
							</div>
						</td>
						<td>
							<FormControl
								aria-label="Small"
								size="sm"
								value={taxableProfit}
								disabled
							/>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Taxes 25%" />
							</div>
						</td>
						<td>
							<FormControl
								aria-label="Small"
								size="sm"
								value={tax}
								disabled
							/>

							{ /*<InputNumber
								className="annualField"
								value={this.props.incomeStatement.Taxes}
								onChange={(value) =>
									this.handleChange("Taxes", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>*/ }
						</td>
					</tr>
					{ /*<tr className="autofillFieldBackground">
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Taxes" />
							</div>
						</td>
						<td>
							<FormControl
								aria-label="Small"
								size="sm"
								value={
									(Math.round(taxableProfit * 2) / 2) * 0.25
								}
								disabled
							/>
						</td>
					</tr>*/ }
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold">
								<Translate
									alias="Forms"
									word="Profit/Loss after taxes"
								/>
							</div>
						</td>
						<td>
							<FormControl
								aria-label="Small"
								size="sm"
								value={profitAfterTax}
								disabled
							/>
						</td>
					</tr>
				</tbody>
			</Table>
		);
	}
}

export default IncomeStatement;
