import React from "react";
import { Container, Row } from "reactstrap";
import Translate from "../../utils/Translate";
import MarketRegion from "./MarketRegion";

export default function LaborMarket({ laborMarket }) {
	return (
		<Container className="market-container m-auto border text-center">
			<span className="region-block-title text-white">
				<Translate alias="Board" word="LABOR MARKET" />
			</span>

			<Row className="market-region-blocks" noGutters={true} xs={2}>
				<MarketRegion id={1} data={laborMarket["region_1"]} />
				<MarketRegion
					id={2}
					data={laborMarket["region_2"]}
					className="custom-border-space"
				/>
				<MarketRegion id={4} data={laborMarket["region_4"]} />
				<MarketRegion
					id={3}
					data={laborMarket["region_3"]}
					className="custom-border-space"
				/>
			</Row>
		</Container>
	);
}
