import React from "react";
import { useDrop } from "react-dnd";
import ReactTooltip from "react-tooltip";

// Utils
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";
import Translate from "../../utils/Translate";

// Components
import DroppableContainer from "../draggable/DroppableContainer";
import DroppableLabel from "../draggable/DroppableLabel";

export default function Location({
	canEdit,
	cashCoinsCount,
	el,
	dispatch,
	regionIndex,
	index,
	coinsCount,
	alias,
	basicLocationLblCount,
	premiumLocationLblCount,
	rented,
}) {
	const [{ isOver }, drop] = useDrop({
		accept: items.container,
		collect: (monitor) => ({
			isOver: monitor.isOver(),
		}),
		drop: (item) => {
			const obj = {
				regionIndex: regionIndex,
				alias: alias,
				name: index,
				coins: item.coins ? item.coins : [],
			};
			RealtimeConnection.addContainer(obj);
			return { success: true };
		},
	});

	const canDrop = canEdit ? drop : null;
	return (
		<div className="eq-block m-2 ">
			<div className="location-header">
				<p className="sm-header line-0 pb-2 pl-1 pt-1"><Translate alias="Board" word={el.name} /></p>
				<div
					data-tip
					data-for={`location_${regionIndex}`}
					className="help-icon"
				>
					?
				</div>
				<ReactTooltip
					id={`location_${regionIndex}`}
					place="top"
					type="dark"
					effect="solid"
				>
					<table style={{ width: "117px" }}>
						<thead>
							<tr>
								<th></th>
								<th>
									<Translate alias="Board" word="Buy" />
								</th>
								<th>
									<Translate alias="Board" word="Rent" />
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td style={{ textAlign: "left" }}>
									<Translate alias="Board" word="Basic" />
								</td>
								<td>5</td>
								<td>3</td>
							</tr>
							<tr>
								<td style={{ textAlign: "left" }}>
									<Translate alias="Board" word="Exclusive" />
								</td>
								<td>10</td>
								<td>5</td>
							</tr>
						</tbody>
					</table>
				</ReactTooltip>
			</div>

			<DroppableContainer
				canEdit={canEdit}
				rented={rented}
				cashCoinsCount={cashCoinsCount}
				containerDrop={canDrop}
				containerIsOver={isOver}
				regionIndex={regionIndex}
				hasContainer={el.hasContainer}
				name={el.name}
				fieldIndex={index}
				alias={alias}
				dispatch={dispatch}
				coins={el.coins}
				coinsCount={coinsCount}
				playerId={el.playerId}
				isObjectDragging={el.isObjectDragging}
				draggingObject={el.draggingObject}
				location={true}
			/>
			<DroppableLabel
				canEdit={canEdit}
				dropType={items.location}
				hasLabel={el.status === "" ? false : true}
				labelType={el.status}
				regionIndex={regionIndex}
				alias={alias}
				name={index}
				dispatch={dispatch}
				basicLblCount={basicLocationLblCount}
				premiumLblCount={premiumLocationLblCount}
			/>
		</div>
	);
}
