import React from "react";
import { DragPreviewImage, useDrag } from "react-dnd";

// Images
import grey from "../../assets/images/avatar-1.png";
import greyPreview from "../../assets/images/avatar-1-preview.png";

import yellow from "../../assets/images/avatar-2.png";
import yellowPreview from "../../assets/images/avatar-2-preview.png";

import white from "../../assets/images/white-avatar.png";
import whitePreview from "../../assets/images/white-avatar-preview.png";

import green from "../../assets/images/avatar.png";
import greenPreview from "../../assets/images/avatar-preview.png";

import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection"

export default function MainDraggableAvatar({ canEdit, color }) {
	const [, drag, preview] = useDrag({
		item: {
			type: items[color],
			color: color,
			isMain: true
		},
		end: (item, monitor) => {
			console.log(item)
			if (monitor.didDrop()) {
				let droppedItem = monitor.getDropResult();
				if (droppedItem !== null && droppedItem.success) {
					let avatarType = ""
					switch(color) {
						case "yellow":
							avatarType = "goldAvatar"
							break
						case "grey":
							avatarType = "silverAvatar"
							break
						case "green":
							avatarType = "greenAvatar"
							break
						default:
							break;
					}
					if (avatarType !== "") {
						const obj = {
							type: avatarType
						}
						RealtimeConnection.decreaseTeamTable(obj)
					}
				}
			}
		},
	});
	let Img = white;
	let previewImg = white;

	switch (color) {
		case "grey":
			Img = grey;
			previewImg = greyPreview;
			break;
		case "yellow":
			Img = yellow;
			previewImg = yellowPreview;
			break;
		case "white":
			Img = white;
			previewImg = whitePreview;
			break;
		case "green":
			Img = green;
			previewImg = greenPreview;
			break;
		default:
			break;
	}

	const canDrag = (canEdit) ? drag : null
	return (
		<>
			<DragPreviewImage connect={preview} src={previewImg} />
			<div className="draggable-coins-container" ref={canDrag}>
				<img alt="draggable-coins" className="draggable-coins" src={Img} />
			</div>
		</>
	);
}
