import React from "react";
import { useDrop } from "react-dnd";

// Utils
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

// Components
import DraggableAvatar from "./DraggableAvatar";

export default function AvatarContainer({
	canEdit,
	color,
	avatarCount,
	regionIndex,
	el,
	currentYear,
}) {
	const [{ isOver }, drop] = useDrop({
		accept:
			color === "green" ? [items[color], items.white] : [items[color]],
		collect: (monitor) => ({
			isOver: monitor.isOver(),
		}),
		drop: (item) => {
			if (el.count < el.max) {
				if (regionIndex !== item.regionIndex) {
					let obj = {
						regionIndex: regionIndex,
						color: item.color,
					};
					if (item.isMain) {
						obj.year = currentYear;
						obj.lastRegion = -1;
					} else {
						obj.year = item.hiredYear;
						obj.lastRegion = item.regionIndex;
					}

					if (item.park) {
						return {};
					}
					if (item.color === "white") {
						RealtimeConnection.addAvatar(obj);
						return { success: true };
					} else {
						if (avatarCount > 0) {
							RealtimeConnection.addAvatar(obj);
							return { success: true };
						}
					}
				}
			}
		},
	});

	const canDrop = canEdit ? drop : null;

	return (
		<div
			className={`avatar-container ${color}-avatar-container`}
			ref={canDrop}
		>
			<div
				className={`${color}-block`}
				style={isOver ? { opacity: "0.7" } : { opacity: "0.9" }}
			></div>
			<div className="avatars-wrapper">
				{color === "green" ? (
					<>
						{el.list.map((e, index) => (
							<DraggableAvatar
								canEdit={canEdit}
								key={index}
								index={index}
								color={color}
								regionIndex={regionIndex}
								characterDetails={e}
								currentYear={currentYear}
							/>
						))}
						{el.whiteList.map((e, i) => (
							<DraggableAvatar
								canEdit={canEdit}
								key={i}
								index={i}
								color={"white"}
								regionIndex={regionIndex}
								characterDetails={e}
								currentYear={currentYear}
							/>
						))}
						{[...Array(el.max - el.count)].map((e, index) => (
							<div
								key={index}
								className="avatar-placeholder"
								style={{ background: color }}
							/>
						))}
					</>
				) : (
					<>
						{el.list.map((e, index) => (
							<DraggableAvatar
								canEdit={canEdit}
								key={index}
								index={index}
								color={color}
								regionIndex={regionIndex}
								characterDetails={e}
								currentYear={currentYear}
							/>
						))}
						{[...Array(el.max - el.count)].map((e, index) => (
							<div
								key={index}
								className="avatar-placeholder"
								style={{ background: color }}
							/>
						))}
					</>
				)}
			</div>
		</div>
	);
}
