const items = {
  container: "Container",
  coin: "Coin",
  time: "Time",
  motivation: "Motivation",
  pin: "Pin",
  green: "Green",
  grey: "Grey",
  yellow: "Yellow",
  white: "White",
  token: "Token",
  location: "Location",
  equipment: "Equipment"
};

export default items;
