import React, { useEffect, useState } from "react";
import checkListIcon from "../../assets/images/checklist-icon.png";
import documentIcon from "../../assets/images/document-icon.png";
import urls from "../../utils/urls";
import checkList from "./Checklist";

import AnnualReport from "../Forms/AnnualReport";
import PlanningSheet from "../Forms/PlanningSheet";
import Translate from "../../utils/Translate";
import axios from "axios";

export default function FixedRightBar({
	canEdit,
	dispatch,
	formStatus,
	planningSheet,
	annualReport,
	session,
	checklistGroups,
}) {
	const [isMenuOpened, setMenuOpened] = useState(false);
	const [isCheckListOpened, setCheckListOpened] = useState(false);
	const [checklistData, setChecklistData] = useState([]);

	function openAnnual(e) {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		dispatch({ type: "annualReport", value: true });
	}
	function openPlanning(e) {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		dispatch({ type: "planningSheet", value: true });
	}

	function toggleFilesMenu() {
		if (isMenuOpened) {
			setMenuOpened(false);
			updateUI("FILES", "close");
		} else {
			setMenuOpened(true);
			setCheckListOpened(false);
			updateUI("FILES", "open");
		}
	}

	function toggleCheckListMenu() {
		if (isCheckListOpened) {
			setCheckListOpened(false);
			updateUI("CHECKLIST", "close");
		} else {
			setCheckListOpened(true);
			setMenuOpened(false);
			updateUI("CHECKLIST", "open");
		}
	}

	const fetchChecklist = async () => {
		/*const result = await axios.post(
			urls.checklist,
			{
				languageId: localStorage.langId,
			},
			{ headers: { "content-type": "application/json" } }
		);

		if (result.data.data) {
			setChecklistData(result.data.data);
		}*/
	};

	useEffect(() => {
		if (checklistGroups) {
			setChecklistData(checklistGroups);
		} else {
			fetchChecklist();
		}
	}, [checklistGroups]);

	function checkListHtml(data) {
		return { __html: data };
	}
	function updateUI(panelType, status) {
		const appContainer =
			document.getElementsByClassName("center-container");
		const filesContainer =
			document.getElementsByClassName("filesContainer");
		const checkListContainer =
			document.getElementsByClassName("checkListContainer");

		if (status === "close") {
			appContainer[0].classList.remove("moveRight");
			filesContainer[0].classList.remove("fullWidth");
			checkListContainer[0].classList.remove("fullWidth");
		} else {
			if (panelType === "FILES") {
				appContainer[0].classList.add("moveRight");
				window.scrollTo({
					left: 380,
					behavior: "smooth",
				});
				filesContainer[0].classList.add("fullWidth");
				checkListContainer[0].classList.remove("fullWidth");
			} else if (panelType === "CHECKLIST") {
				appContainer[0].classList.add("moveRight");
				window.scrollTo({
					left: 380,
					behavior: "smooth",
				});
				checkListContainer[0].classList.add("fullWidth");
				filesContainer[0].classList.remove("fullWidth");
			} else {
				this.setState(
					{ isMenuOpened: false, isCheckListOpened: false },
					() => {
						filesContainer[0].classList.remove("fullWidth");
						checkListContainer[0].classList.remove("fullWidth");
					}
				);
			}
		}
	}
	return (
		<>
			<div className="files-library-btn">
				<div className="section" onClick={toggleCheckListMenu}>
					<div className="button checklist">
						<img alt="checkListIcon" src={checkListIcon} />
						<div
							className={`text ${
								parseInt(localStorage.langId) === 46
									? "transform"
									: ""
							}`}
						>
							<Translate alias="Board" word="Check List" />
						</div>
					</div>
				</div>
				<div className="section" onClick={toggleFilesMenu}>
					<div className="button">
						<img alt="documentIcon" src={documentIcon} />
						<div
							className={`text ${
								parseInt(localStorage.langId) === 46
									? "transform"
									: ""
							}`}
						>
							<Translate alias="Board" word="Files Library" />
						</div>
					</div>
				</div>
			</div>
			<div className="checkListContainer">
				<div className="files-header">
					<div className="files-header-logo">
						<img alt="checkListIcon" src={checkListIcon} />
						<div className="text">
							<Translate alias="Board" word="Check List" />
						</div>
					</div>
				</div>
				<div className="blur">
					{checklistData.Managing3ChecklistGroups
						? checklistData.Managing3ChecklistGroups.map((obj) => {
								return (
									<div
										className="checkListRowContainer"
										key={obj.id}
									>
										<div className="checkListTitle">
											{obj.name}
										</div>
										{obj.Managing3Checklists &&
											obj.Managing3Checklists.sort(
												(a, b) => a.order - b.order
											).map((row) => {
												return (
													<div
														className="checkListRow"
														key={row.id}
													>
														<div className="checkListSubtitle">
															{row.title}
														</div>
														<div
															className="checkListValue"
															dangerouslySetInnerHTML={checkListHtml(
																row.subtitle
															)}
														/>
														<div
															style={{
																fontSize: 12,
																marginBottom:
																	"1rem",
															}}
															dangerouslySetInnerHTML={checkListHtml(
																row.actions
															)}
														></div>
													</div>
												);
											})}
									</div>
								);
						  })
						: checklistData.map((obj) => {
								return (
									<div
										className="checkListRowContainer"
										key={obj.id}
									>
										<div className="checkListTitle">
											{obj.name}
										</div>
										{obj.Managing3Checklists &&
											obj.Managing3Checklists.sort(
												(a, b) => a.order - b.order
											).map((row) => {
												return (
													<div
														className="checkListRow"
														key={row.id}
													>
														<div className="checkListSubtitle">
															{row.title}
														</div>
														<div
															className="checkListValue"
															dangerouslySetInnerHTML={checkListHtml(
																row.subtitle
															)}
														/>
														<div
															style={{
																fontSize: 12,
																marginBottom:
																	"1rem",
															}}
															dangerouslySetInnerHTML={checkListHtml(
																row.actions
															)}
														></div>
													</div>
												);
											})}
									</div>
								);
						  })}
				</div>
			</div>
			<div className="filesContainer">
				<div className="files-header">
					<div className="files-header-logo">
						<img alt="documentIcon" src={documentIcon} />
						<div className="text">
							<Translate alias="Board" word="Files Library" />
						</div>
					</div>
				</div>
				<div className="blur">
					{!formStatus.planningSheet && !formStatus.annualReport && (
						<div className="files-section">
							<div className="filesListTitle">
								<Translate alias="Forms" word="Forms" />
							</div>
							<div className="fileRow">
								<div
									className="file-link form-link"
									onClick={openPlanning}
								>
									<div>
										<Translate
											alias="Forms"
											word="Planning Sheet"
										/>
									</div>
								</div>
							</div>
							<div className="fileRow">
								<div
									className="file-link form-link"
									onClick={openAnnual}
								>
									<div>
										<Translate
											alias="Forms"
											word="Annual Report"
										/>
									</div>
								</div>
							</div>
						</div>
					)}

					{formStatus.annualReport && (
						<AnnualReport
							canEdit={canEdit}
							dispatch={dispatch}
							session={session}
							annualReport={annualReport}
						/>
					)}
					{formStatus.planningSheet && (
						<PlanningSheet
							canEdit={canEdit}
							dispatch={dispatch}
							planningSheet={planningSheet}
							session={session}
						/>
					)}
					{session.files && session.files.length > 0 && (
						<div className="files-section">
							<div className="filesListTitle">Files</div>
							{session.files.map((obj, index) => {
								return (
									<div className="fileRow" key={index}>
										<div className="file-link">
											<a
												href={`${urls.backendURL}/uploads/sessions/images/${obj.file}`}
												target="_blank"
												rel="noopener noreferrer"
											>
												{obj.name}
											</a>
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
		</>
	);
}
