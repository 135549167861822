const initState = [
	{
		title: "START",
		list: [
			{
				title: ["Pulling a common event card in each regions"],
				value: [],
				actions: ["Trainer is reducing or increasing the number of available employees on the labor labor market in the 4 regions"],
			},
			{
				title: ["Credit repayment"],
				value: [],
				actions: ["Pay from “Cash” and reduce Liabilities"],
			},
			{
				title: ["Possibility to buy forecasts for the total market volume"],
				value: ["3 per forecast"],
				actions: ["Pay from “Cash” to “Market Entry"],
			},
		],
	},
	{
		title: "Location",
		list: [
			{
				title: ["01. Expansion to other regions"],
				value: ["2 per pin (forecast for total market volume 3)"],
				actions: ["Pay from “Cash” to “Market Entry”"],
			},
			{
				title: ["02. Office location - opening", "•	Buy basic or exclusive", "•	Rent basic or exclusive", "Effect for “exclusive”: reputation = +1", "Office location - closing", "Close location", "Effect: reputation = -1"],
				value: ["Buying: 5 = basic or 10 = exclusive", "Renting: 3 = basic or 5 = exclusive", "Closing: 75% / reputation -1"],
				actions: ["Buying: Pay from “Cash” to “Location”", "Renting: Pay from “Cash” to “Lease”", "Closing: Put 75% into “Cash” and 25% into “Depreciation”"],
			},
		],
	},
	{
		title: "People",
		list: [
			{
				title: ["03. Release people and pay compensation fee", "Effect: reputation -1"],
				value: ["per Gold = 4", "per Silver = 2", "per Green = 0,5"],
				actions: ["Pay from “Cash” to “Severance“"],
			},
			{
				title: ["04. Hire People (sign-on fee) “Gold”"],
				value: ["Auction between all companies - in each region"],
				actions: ["Pay from “Cash” to “Sign-on"],
			},
			{
				title: ["05. Hire People (sign-on fee) “Silver”, “Green”, “White”"],
				value: ["For free (no sign-on fee) - in each region"],
				actions: ["Choose people in order of reputation"],
			},
			{
				title: ["06. Sign people from competitors if your reputation is higher. Gold only if reputation of competitor is 1 or 2"],
				value: ["Gold 4 - in each region"],
				actions: ["Pay from “Cash” to “Sign-on”"],
			},
			{
				title: ["07. Sign people from competitors if your reputation is higher. Silver and Green only if Motivation <6"],
				value: ["Silver 2", "Green 0,5"],
				actions: ["Pay from “Cash” to “Sign-on”"],
			},
			{
				title: ["08. Pay salary"],
				value: ["5 Gold", "2 Silver", "1 Green", "0,5 White"],
				actions: ["Pay from “Cash” to “Salary”"],
			},
			{
				title: ["09. Allocate time"],
				value: ["Gold (strategic / leading people / daily tasks / buffer)", "Silver (leading people / customers)"],
				actions: ["Allocate a total of 100% to the different tasks"],
			},
			{
				title: ["10. Invest in training"],
				value: ["This investment is optional: 0,25 per Green (in every region)"],
				actions: ["Pay from “Cash” to “Training”"],
			},
			{
				title: ["11. Invest in equipment"],
				value: ["superior equipment = 10 ", "basic equipment = 5"],
				actions: ["Pay from “Cash” to “Equipment”"],
			},
		],
	},
	{
		title: "SALES",
		list: [
			{
				title: ["12. Marketing"],
				value: [],
				actions: ["Pay from “Cash” to “Marketing” – (maximum is 5 coins per region)"],
			},
			{
				title: ["13. Sales"],
				value: ["Max volume per Silver = 20", "(everything else means over hours and would effect their “motivation”)"],
				actions: ["For each region choose cards in order of the following index: (Reputation + Marketing) X “Motivation & SelfConfidence” of green"],
			},
		],
	},
	{
		title: "CHECK RESULTS",
		list: [
			{
				title: ["14. Check capacity"],
				value: ["I.	Fill-up missing Green with White", "II.	For each region If white ≥ green in a project", "III. Workload", "a) If sales volume > 20 x silver", "b) If sales volume > 24 x silver", "c) If sales volume = 30 x silver (max)", "", "IV. If not enough gold on top (ratio 1 Gold : 3 Silver)"],
				actions: ["I. Pay from “Cash” to “Salary” 1 per white", "II. Effect: reputation = -1 (for each region)", "III. Effect: “Motivation & Self-Confidence”", "a)	Green = -1", "b) Silver = -1 & Green = -2", "c)	Silver = -2 and Green = -2", "IV. Silver = -2 and Green = -1"],
			},
			{
				title: ["15. Define the “Market Leader”"],
				value: ["Formula for calculation: sales x reputation"],
				actions: ["Effect for “the best”:", "reputation = +1"],
			},
			{
				title: ["16. Check people"],
				value: [
					"I.	If you are reputation = +1  in step 15",
					"II. If “Motivation and Self-Confidence” of",
					"a)	Silver <5 & “leading people” Gold <3",
					"b)	Green <5 & “leading people” Silver <7",
					"III. If “Motivation and Self-Confidence” of",
					"a)	Silver <5 & “leading people” Gold >3",
					"b)	Green <5 & “leading people” Silver >7"
				],
				actions: [
					"I.	Effect: “Motivation & Self-Confidence” Silver = +2 and Green = +1 effects each region",
					"II. “Draw Cards”",
					"a) Effect if “card = black”: “Motivation & Self-Confidence” Silver = -2",
					"b) Effect if “card = black”: “Motivation & Self-Confidence” Green = -2",
					"III. “Draw Cards”",
					"a) Effect if “card = green”: “Motivation & Self-Confidence” Silver = +2",
					"b) Effect if “card = green”: “Motivation & Self-Confidence” Green = +2",
				],
			},
			{
				title: ["17. Check Company"],
				value: ["Highest development level of Green Formula for calculation:", "“Motivation & Self-Confidence” + “Skills & Knowledge”"],
				actions: ["Effect: reputation = +1"],
			},
		],
	},
	{
		title: "END OF YEAR",
		list: [
			{
				title: ["18. Pulling an individual event cards per region"],
				value: [],
				actions: [],
			},
			{
				title: ["19. Overheads"],
				value: ["3 per location"],
				actions: ["Pay from “Cash” to “Overheads”"],
			},
			{
				title: ["20. Depreciation of locations"],
				value: ["0,5 per location “basic”", "1 per location “exclusive”"],
				actions: ["Move coins from “Location” to “Depreciation”"],
			},
			{
				title: ["21. Depreciation of equipment"],
				value: ["1 per equipment “basic”", "2 per equipment “superior”"],
				actions: ["Move coins from “Equipment” to “Depreciation”"],
			},
			{
				title: ["22. Interest"],
				value: ["10% of “liabilities”"],
				actions: ["Pay from “cash” to “Interest”"],
			},
			{
				title: ["23. P&L"],
				value: ["Set up P&L until “Earnings before taxes”", "If your profit <5 and “strategic” time of gold <5"],
				actions: ["Reputation -1"],
			},
			{
				title: ["24. Bonus payment"],
				value: ["Pay bonus(optional and only possible if you have a profit in the P&L)", "a) 5 for each region", "or", "b) 10 for each region"],
				actions: ["Pay from “Cash” to “Salary”", "Effect:", "a) “Motivation & Self-Confidence” Silver = +1 and Green = +1", "b) “Motivation & Self-Confidence” Silver = +3 and Green = +2"],
			},
			{
				title: ["25. Taxes"],
				value: ["25% of profit", "Then finish the P&L"],
				actions: ["Pay from “Cash” to “Overheads”"],
			},
		],
	},
];

export default initState;
