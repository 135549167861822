export const initialTeamTableState = {
	coins: 0,
	time: 0,
	tokens: 0,
	pins: 0,
	reputation: 0,
	standardLocation: 1,
	premiumLocation: 0,
	standardEquipment: 1,
	premiumEquipment: 0,
	goldAvatar: 0,
	silverAvatar: 0,
	greenAvatar: 0,
	skills: 0,
	motivation: 0,
	token: {
		value: 10,
		dropped: false,
	},
	pinsCount: 5,
	yellowCount: 30,
	greyCount: 30,
	greenCount: 30,
	coinsCount: 30,
	timeCount: 3,
	motivationCount: 3,
	park: 0
};
