import React, { useState } from "react";
import { Col, Row, Modal } from "reactstrap";

// Components
import LaborMarket from "./LaborMarket";

// Images
import logo from "../../assets/SVG/Asset 13.svg";
import circle3 from "../../assets/SVG/Asset 2.svg";
import circle2 from "../../assets/SVG/Asset 3.svg";
import smiley from "../../assets/SVG/Asset 1.svg";
import arrowUp from "../../assets/SVG/Asset 12.svg";
import laborBtn from "../../assets/images/min-button.png";
import happyFace from "../../assets/images/HappyFace.png";
import sadFace from "../../assets/images/SadFace.png";
import Translate from "../../utils/Translate";
import ParkContainer from "./ParkContainer";

export default function Header({
	laborMarket,
	reputation,
	skills,
	motivation,
	park,
	canEdit,
}) {
	const [modal, setModal] = useState(false);

	const toggle = () => setModal(!modal);

	return (
		<Row className="header-bar pb-1 ml-1" noGutters={true}>
			<div className="side-block" style={{ width: 225 }}>
				<p className="side-blocks-title">
					<Translate alias="Board" word="MY EMPLOYEES" />
				</p>
				<p
					className="side-blocks-title r-2"
					style={{ position: "absolute", textAlign: "end" }}
				>
					<Translate alias="Board" word="GENERAL" />
				</p>
				<div
					className="d-flex flex-column pt-1"
					style={{ marginTop: "-30px" }}
				>
					<img src={circle3} alt="circle3" className="sm-img" />
					<div className="d-flex">
						<img src={circle2} alt="circle2" className="sm-img" />
						<img src={smiley} alt="smiley" className="sm-img" />
					</div>
				</div>
				<Row className="side-blocks" xs={2} noGutters={true}>
					<Col className="draggable-block  yellowish">
						<img
							src={skills >= 0 ? happyFace : sadFace}
							alt="happyFace"
							className="emoji"
						/>
						<input
							type="text"
							value={skills > 0 ? `+${skills}` : `${skills}`}
							readOnly={true}
							className="input-inside yellowish "
							style={{ color: "#1B5B97" }}
						/>
						<p className="sm-header">
							<Translate
								alias="Board"
								word="Skills & Knowledge"
							/>
						</p>
					</Col>

					<Col className="draggable-block  yellowish border-space">
						<img
							src={motivation >= 0 ? happyFace : sadFace}
							alt="happyFace"
							className="emoji"
						/>
						<input
							type="text"
							value={
								motivation > 0
									? `+${motivation}`
									: `${motivation}`
							}
							readOnly={true}
							className="input-inside yellowish"
							style={{ color: "#1B5B97" }}
						/>
						<p className="sm-header ">
							<Translate alias="Board" word="Motivation" />
							{/* <br />& Self-conﬁdence */}
						</p>
					</Col>
				</Row>
			</div>
			<div className="side-block ml-3 " style={{ width: "145px" }}>
				<p className="side-blocks-title">
					<Translate alias="Board" word="REPUTATION" />
				</p>
				<img src={arrowUp} alt="arrowUp" className="arrow-up" />
				<div className="squares-block">
					{[...Array(6)].map((e, i) => (
						<div
							className="square"
							key={i}
							style={
								i < reputation
									? {
											backgroundColor: "#51b948",
											marginTop: i * -20,
									  }
									: { marginTop: i * -20 }
							}
						/>
					))}
				</div>
			</div>

			<Col className="flex-grow-1 text-right labor-btn ">
				<span className="region-block-title text-white p-1">
					<Translate alias="Board" word="LABOR MARKET" />
				</span>
				<img src={laborBtn} alt="laborBtn" onClick={toggle} />
			</Col>
			<Modal isOpen={modal} toggle={toggle}>
				<LaborMarket laborMarket={laborMarket} />
			</Modal>

			<div className="side-block ml-3 " style={{ width: "145px" }}>
				<p className="side-blocks-title">
					<Translate alias="Board" word="Training" />
				</p>
				<ParkContainer avatars={park} canEdit={canEdit} />
			</div>
			<Col className="flex-grow-1 text-right">
				<img
					src={logo}
					alt="logo"
					width="260"
					style={{ marginRight: "-7rem" }}
				/>
			</Col>
		</Row>
	);
}
