import React from "react";
import { Col } from "reactstrap";

// Utils
import RealtimeConnection from "../../utils/RealtimeConnection";
import Translate from "../../utils/Translate";

export default function Time({
	el,
	timesList,
	timeIndex,
	regionIndex,
	alias,
	allowTimeAllocation,
}) {
	function addTime() {
		if (allowTimeAllocation) {
			let total = 0;
			for (var i in timesList) {
				total += timesList[i].value;
			}

			if (total <= 90 && el.value <= 90) {
				const obj = {
					name: el.name,
					regionIndex: regionIndex,
					timeIndex: timeIndex,
					alias: alias,
				};
				RealtimeConnection.addTime(obj);
			}
		}
	}
	function subtractTime() {
		if (allowTimeAllocation && el.value > 0) {
			const obj = {
				name: el.name,
				regionIndex: regionIndex,
				timeIndex: timeIndex,
				alias: alias,
			};
			RealtimeConnection.subtractTime(obj);
		}
	}

	return (
		<Col className={`draggable-block ${el.customClass}`}>
			<button className="minus-icon ml-1" onClick={subtractTime}>
				-
			</button>
			<input
				type="text"
				readOnly={true}
				className="input-inside py-2 px-3"
				value={`${el.value}%`}
				key={el.name}
				style={{ background: "white" }}
			/>
			<button className="minus-icon mr-1" onClick={addTime}>
				+
			</button>
			<p className="sm-header text-white">
				<Translate alias="Board" word={el.name} />{" "}
			</p>
		</Col>
	);
}
