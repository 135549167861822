import React from "react";
import { Container, Row } from "reactstrap";
import Cash from "../CenterComponents/Cash";
import Liabilities from "../CenterComponents/Liabilities";
import RegionBlock from "./Region";
import RealtimeConnection from "../../utils/RealtimeConnection";
import InputNumber from "rc-input-number";
import Translate from "../../utils/Translate";

export default function CenterBlock({
	canEdit,
	state,
	dispatch,
	cashCoinsCount,
	allowTimeAllocation,
}) {
	return (
		<Container
			className="center-block p-0 mb-3"
			noGutters={true}
			fluid={true}
		>
			<div className="header p-1 d-flex">
				<div className="draggable-block greyish ">
					<div className="d-flex">
						{state.Board.cash.map((el, index) => {
							return (
								<Cash
									canEdit={canEdit}
									key={index}
									{...el}
									index={index}
									state={state}
									dispatch={dispatch}
									alias={"cash"}
								/>
							);
						})}
					</div>
					<p className="sm-header">
						<Translate alias="Board" word="Cash" />
					</p>
				</div>

				<div className="d-flex mr-5 ml-auto">
					<p className="sm-header text-white mr-1 mt-1">
						<Translate alias="Board" word="Equity" />
					</p>
					<div
						className="draggable-block greyish p-1"
						style={{ width: "6rem" }}
					>
						<InputNumber
							max="999"
							min="-999"
							precision="1"
							name="equity"
							readOnly={canEdit ? false : true}
							value={
								state.Board.equity !== 0
									? state.Board.equity
									: null
							}
							onChange={(value) =>
								RealtimeConnection.addEquity({
									value: value,
								})
							}
						/>
					</div>
				</div>

				<div className="d-flex ml-3 mr-5">
					<p className="sm-header text-white mr-1 mt-1">
						<Translate alias="Board" word="Liabilities" />
					</p>

					<div className="draggable-block whitish reddish-border p-0 ">
						<Liabilities
							canEdit={canEdit}
							el={state.Board.liabilty}
							totalTokens={state.TeamTable.tokens}
							dispatch={dispatch}
						/>
					</div>
				</div>
			</div>

			<Row className="region-blocks" noGutters={true} xs={2}>
				{state.Board.regions.map((region, index) => (
					<RegionBlock
						key={index}
						canEdit={canEdit}
						region={region}
						index={index}
						cashCoinsCount={cashCoinsCount}
						state={state}
						dispatch={dispatch}
						allowTimeAllocation={allowTimeAllocation}
					/>
				))}
			</Row>
		</Container>
	);
}
