const initState = {
	incomeStatement: {
		salesRevenue: 0,
		Salary: 0,

		SignOn: 0,
		Training: 0,
		Severance: 0,
		MarketEntry: 0,
		Marketing: 0,
		Lease: 0,
		Depreciation: 0,
		Overheads: 0,

		Interest: 0,

		Bonus: 0,

		LossCarriedForward: 0,
		Taxes: 0,

		ProfitLossAfterTaxes: 0,
	},
	balanceSheet: {
		Locations: 0,
		Equipments: 0,
		cash: 0,
		equity: 0,
		liabilities: 0,
	},
	cashFlow: {
		cashLevelAtStart: 0,
		salesRevenue: 0,

		Salary: 0,
		SignOn: 0,
		Training: 0,
		Severance: 0,
		MarketEntry: 0,
		Marketing: 0,
		Lease: 0,
		Depreciation: 0,
		Overheads: 0,

		Investments: 0,

		Interest: 0,
		Taxes: 0,
		Repayments: 0,
	},
};

export default initState;
