import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import socketIOClient from "socket.io-client";

// Constants
import URLs from "../utils/urls";

// Components
import CenterBlock from "./partials/CenterBlock";
import FixedHeader from "./partials/FixedHeader";
import Header from "./partials/Header";
import LeftBlock from "./partials/LeftBlock";
import RightBlock from "./partials/RightBlock";
import FixedLeftBar from "./partials/FixedLeftBar";
import FixedRightBar from "./partials/FixedRightBar";
import CommonEventPopup from "./partials/CommonEventPopup";

import { StateProvider, StateContext, DispatchContext } from "./stateContext";
import DragAndDrop from "./Drag&Drop";

let scrollIntervalTime = undefined;

export default function M3Board(props) {
	const state = useContext(StateContext);
	const dispatch = useContext(DispatchContext);

	const [canEdit, setCanEdit] = useState(false);
	const [socket, setSocket] = useState(undefined);
	const [counter, setCounter] = useState(undefined);
	const [teamName, setTeamName] = useState("");

	const [zoomValue, setZoom] = useState(100);

	//const [mousePosition, setMousePosition] = useState({ x: null, y: null })
	/*const updateMousePosition = ev => {
		setMousePosition({ x: ev.clientX, y: ev.clientY });
	}*/
	/*useEffect(() => {
		window.addEventListener("mousemove", updateMousePosition);
		return () => window.removeEventListener("mousemove", updateMousePosition);
	}, [])*/
	/*
	useEffect(() => {
		console.log(mousePosition.x)
		if (mousePosition.x >= (window.innerWidth - 100)) {
			if (!scrollIntervalTime) {
				scrollIntervalTime = setInterval( () => {
					scrollRightAction()
				}, 50)
			}
		}else if (mousePosition.x <= 100) {
			if (!scrollIntervalTime) {
				scrollIntervalTime = setInterval( () => {
					scrollLeftAction()
				}, 50)
			}
		}else{
			if (scrollIntervalTime) {
				clearInterval(scrollIntervalTime)
				scrollIntervalTime = undefined
			}
		}
	}, [mousePosition])
	*/

	const updateDrageMousePosition = (ev) => {
		//console.log(ev)
		if (ev.clientX >= window.innerWidth - 100) {
			if (!scrollIntervalTime) {
				scrollIntervalTime = setInterval(() => {
					scrollRightAction();
				}, 50);
			}
		} else if (ev.clientX <= 100) {
			if (!scrollIntervalTime) {
				scrollIntervalTime = setInterval(() => {
					scrollLeftAction();
				}, 50);
			}
		} else {
			if (scrollIntervalTime) {
				clearInterval(scrollIntervalTime);
				scrollIntervalTime = undefined;
			}
		}
	};

	useEffect(() => {
		window.addEventListener("dragover", updateDrageMousePosition);
		return () =>
			window.removeEventListener("dragover", updateDrageMousePosition);
	}, []);

	const scrollRightAction = () => {
		window.scrollBy({
			left: 50,
			behavior: "smooth",
		});

		if (
			document.body.scrollWidth - window.innerWidth ===
			window.pageXOffset - (window.innerWidth - document.body.offsetWidth)
		) {
			if (scrollIntervalTime) {
				clearInterval(scrollIntervalTime);
				scrollIntervalTime = undefined;
			}
		}
	};

	const scrollLeftAction = () => {
		window.scrollBy({
			left: -50,
			behavior: "smooth",
		});

		if (window.pageXOffset === 0) {
			if (scrollIntervalTime) {
				clearInterval(scrollIntervalTime);
				scrollIntervalTime = undefined;
			}
		}
	};

	useEffect(() => {
		setCanEdit(props.canEdit);
	}, [props.canEdit]);

	useEffect(() => {
		if (!window.socket) {
			setupSocket();
		}
	}, []);

	useEffect(() => {
		setTeamName(props.teamNameValue);
	}, [props.teamNameValue]);

	const setupSocket = () => {
		const socketObj = socketIOClient(URLs.socketEndpoint, {
			upgrade: true,
			transports: ["websocket", "polling"], //['websocket', 'polling']
		});
		window.socket = socketObj;

		setSocket(socketObj);
	};

	useEffect(() => {
		if (socket) {
			socket.on("connect", (data) => {
				console.log("Connected To WS");

				socket.emit("user-data", {
					user_id: window.playerId,
					team_id: window.teamId,
					session_id: window.sessionId,
					token: window.token,
				});
			});

			socket.on("disconnect", (data) => {
				console.log("Disconnected From WS");
			});

			socket.on("restore-session", (data) => {
				dispatch({
					type: "RestoreTeamTable",
					data: data.starterKitStateObj,
				});
				dispatch({ type: "RestoreBoard", data: data.state });
				dispatch({ type: "changeYear", year: data.year });
				dispatch({ type: "files", files: data.files });
				dispatch({ type: "RestoreForm", data: data.sessionForms });

				if (data.messages) {
					dispatch({ type: "RestoreMsgs", data: data.messages });
				}
			});

			socket.on("restore-labor-market", (data) => {
				dispatch({ type: "RestoreLaborMarket", data: data });
			});

			socket.on("change-year", (data) => {
				dispatch({ type: "changeYear", year: data.year });
				dispatch({
					type: "changeFormYear",
					data: data,
				});
			});
			socket.on("change-form-year", (data) => {
				dispatch({
					type: "changeFormYear",
					data: data,
				});
			});
			socket.on("change-location-status", (data) => {
				dispatch({
					type: "locationStatus",
					...data,
				});
			});

			socket.on("add-timer", (data) => {
				console.log(data);
				setCounter(data.data);
			});

			socket.on("remove-timer", (data) => {
				setCounter(undefined);
			});

			socket.on("update-labor-market", (data) => {
				dispatch({ type: "LaborMarket", data: data });
			});

			socket.on("update-team-table", (data) => {
				dispatch({ type: "UpdateTeamTable", data: data });
			});

			socket.on("update-region-customer-data", (data) => {
				dispatch({ type: "UpdateRegionCustomerData", data: data });
			});

			socket.on("update-region-motivation-value", (data) => {
				dispatch({ type: "UpdateRegionMotivationValue", data: data });
			});

			socket.on("decrease-starter-kit", (data) => {
				dispatch({ type: "DecreaseTeamTable", data: data });
			});

			socket.on("increase-starter-kit", (data) => {
				dispatch({ type: "IncreaseTeamTable", data: data });
			});
			socket.on("decrease-cash", (data) => {
				dispatch({ type: "DecreaseCash", data: data });
			});

			socket.on("increase-cash", (data) => {
				dispatch({ type: "IncreaseCash", data: data });
			});

			socket.on("add-container", (data) => {
				dispatch({ type: "addContainer", ...data });
			});
			socket.on("updateForm", (data) => {
				if (data.type === "planningSheet") {
					dispatch({ type: "updatePlanningSheet", data: data });
				} else {
					dispatch({ type: "updateAnnualReport", data: data });
				}
			});
			socket.on("annualReport", (data) => {
				dispatch({ type: "changeAnnualReport", ...data });
			});

			socket.on("remove-container", (data) => {
				dispatch({ type: "removeContainer", ...data });
			});

			socket.on("add-coin", (data) => {
				dispatch({ type: "addCoin", ...data });
			});

			socket.on("allow-time-allocation-status", (data) => {
				dispatch({ type: "allowTimeAllocation", ...data });
			});

			socket.on("remove-coin", (data) => {
				dispatch({ type: "removeCoin", ...data });
			});

			socket.on("add-equity", (data) => {
				dispatch({ type: "addEquity", ...data });
			});

			socket.on("add-token", (data) => {
				dispatch({ type: "addToken", ...data });
			});

			socket.on("remove-token", (data) => {
				dispatch({ type: "deleteToken", ...data });
			});

			socket.on("add-label", (data) => {
				dispatch({ type: "addLabel", ...data });
			});

			socket.on("remove-label", (data) => {
				dispatch({ type: "deleteLabel", ...data });
			});

			socket.on("add-avatar", (data) => {
				dispatch({ type: "addAvatar", ...data });
			});

			socket.on("remove-avatar", (data) => {
				dispatch({ type: "deleteAvatar", ...data });
			});

			socket.on("add-pin", (data) => {
				dispatch({ type: "addPin", ...data });
			});

			socket.on("remove-pin", (data) => {
				dispatch({ type: "deletePin", ...data });
			});

			socket.on("add-time", (data) => {
				dispatch({ type: "addTime", ...data });
			});

			socket.on("subtract-time", (data) => {
				dispatch({ type: "subtractTime", ...data });
			});

			socket.on("container-dragging", (data) => {
				dispatch({ type: "containerDragging", ...data });
			});
			socket.on("coin-dragging", (data) => {
				dispatch({ type: "coinDragging", ...data });
			});

			socket.on("add-file", (data) => {
				if (
					parseInt(data.teamId) === parseInt(window.teamId) ||
					parseInt(data.teamId) === 0
				) {
					console.log(data);

					dispatch({
						type: "addFiles",

						files: data,
					});
				}
			});

			socket.on("remove-file", (data) => {
				if (
					parseInt(data.teamId) === parseInt(window.teamId) ||
					parseInt(data.teamId) === 0
				) {
					dispatch({
						type: "removeFiles",
						files: data,
					});
				}
			});

			socket.on("change-team", (data) => {
				setTeamName(data);
			});
			socket.on("session-status-changed", (data) => {
				window.location.reload();
			});

			socket.on("session-active-event", (data) => {
				dispatch({ type: "sessionActiveEventId", data: data });
			});

			socket.on("list-message", (data) => {
				dispatch({ type: "messagesList", message: { ...data } });
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket]);

	const cashCoins = state.Board.cash.map((x) => {
		let cashCoin = 0;
		cashCoin += x.coins.reduce((a, b) => a + b, 0);
		return cashCoin;
	});
	return (
		<DragAndDrop>
			<StateProvider>
				<div className="App">
					<FixedHeader
						canEdit={canEdit}
						counter={counter}
						teamName={teamName}
						messages={state.Session.messages}
						dispatch={dispatch}
						setZoom={setZoom}
						zoomValue={zoomValue}
						languages={props.languages}
						chatMessages={state.Session.messages}
					/>
					<FixedLeftBar
						canEdit={canEdit}
						state={state.TeamTable}
						dispatch={dispatch}
					/>
					<Row
						id="CenterContainer"
						className={` center-container zoom${zoomValue}`}
						noGutters={true}
					>
						<Col className="left-side-bar side-bar p-0 flex-grow-0">
							{state.Board.leftBlock.map((item, index) => (
								<LeftBlock
									key={index}
									canEdit={canEdit}
									id={index}
									{...item}
									coinsCount={state.TeamTable.coins}
									cashCoinsCount={cashCoins}
									dispatch={dispatch}
									alias="leftBlock"
								/>
							))}
						</Col>
						<Col className="p-0 flex-grow-10">
							<Header
								laborMarket={state.LaborMarket}
								park={state.TeamTable.park}
								reputation={state.TeamTable.reputation}
								skills={state.TeamTable.skills}
								motivation={state.TeamTable.motivation}
								canEdit={canEdit}
							/>
							<CenterBlock
								canEdit={canEdit}
								state={state}
								cashCoinsCount={cashCoins}
								dispatch={dispatch}
								allowTimeAllocation={
									state.Session["allowTimeAllocation"]
								}
							/>
						</Col>
						<Col className="right-side-bar side-bar p-0 flex-grow-0">
							{state.Board.rightBlock.map((item, index) => (
								<RightBlock
									key={index}
									canEdit={canEdit}
									id={index}
									{...item}
									coinsCount={state.TeamTable.coins}
									cashCoinsCount={cashCoins}
									depreciation={
										item.name === "Depreciation"
											? true
											: false
									}
									dispatch={dispatch}
									alias="rightBlock"
								/>
							))}
						</Col>
					</Row>
					<FixedRightBar
						canEdit={canEdit}
						dispatch={dispatch}
						formStatus={state.Session["formStatus"]}
						planningSheet={state.planningSheet}
						annualReport={state.annualReport}
						session={state.Session}
						checklistGroups={props.checklistGroups}
					/>

					{state.Session.currentEventId && (
						<CommonEventPopup
							eventId={state.Session.currentEventId}
						/>
					)}
				</div>
			</StateProvider>
		</DragAndDrop>
	);
}
