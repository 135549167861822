import React from "react";
import { DragPreviewImage, useDrag } from "react-dnd";

// Images
import pin from "../../assets/images/pushpin2.png";
import previewImg from "../../assets/images/pushpin-preview.png";

// Utils
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

export default function DraggablePin({ canEdit, e, regionIndex, pinNumber }) {
	const [, drag, preview] = useDrag({
		item: { type: items.pin, regionIndex: regionIndex },
		end: (item, monitor) => {
			if (monitor.didDrop()) {
				let droppedItem = monitor.getDropResult();
				if (droppedItem !== null && droppedItem.success) {
					const obj = {
						regionIndex: regionIndex,
						pinNumber: pinNumber
					}
					RealtimeConnection.removePin(obj)
				}
			}
		}
	});

	const canDrag = (canEdit) ? drag : null
	return (
		<>
			<DragPreviewImage connect={preview} src={previewImg} />
			{e.hasPin && <img src={pin} alt="pin" className="w-100" ref={canDrag} />}
		</>
	);
}
