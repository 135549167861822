import React from "react";
import { useDrag } from "react-dnd";

// Utils
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection"

export default function MainDraggableLiability({ canEdit, value }) {
	const [, drag] = useDrag({
		item: {
			type: items.token,
			value: value
		},
		end: (item, monitor) => {
			if (monitor.didDrop()) {
				let droppedItem = monitor.getDropResult();
				if (droppedItem !== null && droppedItem.success) {
					const obj = {
						type: "tokens",
						value: value
					}
					RealtimeConnection.decreaseTeamTable(obj)
				}
			}
		},
	});

	const canDrag = (canEdit) ? drag : null
	return (
		<div className="tokenDraggable" ref={canDrag}>
			<div className="Token-container-draggable">
				<div className="Token-value">{value}</div>
				<div className="Token-text"> liability </div>
			</div>
		</div>
	);
}
