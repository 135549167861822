import React from "react";
import { Table, FormControl } from "react-bootstrap";
import InputNumber from "rc-input-number";
import RealtimeConnection from "../../../utils/RealtimeConnection";
import Translate from "../../../utils/Translate";

class CashFlow extends React.Component {
	handleChange(field, value) {
		if (this.props.canEdit) {
			RealtimeConnection.changeAnnualReport({
				year: this.props.year,
				groupName: "cashFlow",
				field: field,
				value: value,
			});
		}
	}

	render() {
		let salesRevenue = 0;
		salesRevenue += !isNaN(parseFloat(this.props.cashFlow.salesRevenue))
			? parseFloat(this.props.cashFlow.salesRevenue)
			: 0;
		salesRevenue += !isNaN(parseFloat(this.props.cashFlow.cashLevelAtStart))
			? parseFloat(this.props.cashFlow.cashLevelAtStart)
			: 0;

		let totalOverhead = 0;
		totalOverhead += !isNaN(parseFloat(this.props.cashFlow.SignOn))
			? parseFloat(this.props.cashFlow.SignOn)
			: 0;
		totalOverhead += !isNaN(parseFloat(this.props.cashFlow.Salary))
			? parseFloat(this.props.cashFlow.Salary)
			: 0;
		totalOverhead += !isNaN(parseFloat(this.props.cashFlow.Severance))
			? parseFloat(this.props.cashFlow.Severance)
			: 0;
		totalOverhead += !isNaN(parseFloat(this.props.cashFlow.MarketEntry))
			? parseFloat(this.props.cashFlow.MarketEntry)
			: 0;
		totalOverhead += !isNaN(parseFloat(this.props.cashFlow.Marketing))
			? parseFloat(this.props.cashFlow.Marketing)
			: 0;
		totalOverhead += !isNaN(parseFloat(this.props.cashFlow.Lease))
			? parseFloat(this.props.cashFlow.Lease)
			: 0;
		totalOverhead += !isNaN(parseFloat(this.props.cashFlow.Training))
			? parseFloat(this.props.cashFlow.Training)
			: 0;
		totalOverhead += !isNaN(parseFloat(this.props.cashFlow.Overheads))
			? parseFloat(this.props.cashFlow.Overheads)
			: 0;

		let FreeCashFlow = salesRevenue - totalOverhead;

		let NetCashFlow = FreeCashFlow;

		NetCashFlow -= !isNaN(parseFloat(this.props.cashFlow.Investments))
			? parseFloat(this.props.cashFlow.Investments)
			: 0;

		let CashLevel = NetCashFlow;
		CashLevel -= !isNaN(parseFloat(this.props.cashFlow.Repayments))
			? parseFloat(this.props.cashFlow.Repayments)
			: 0;
		CashLevel -= !isNaN(parseFloat(this.props.cashFlow.Taxes))
			? parseFloat(this.props.cashFlow.Taxes)
			: 0;
		CashLevel -= !isNaN(parseFloat(this.props.cashFlow.Interest))
			? parseFloat(this.props.cashFlow.Interest)
			: 0;

		return (
			<Table bordered>
				<tbody>
					<tr>
						<td>
							<div className="bold">
								<Translate
									alias="Forms"
									word="Cash level at start of year"
								/>
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.cashFlow.cashLevelAtStart}
								onChange={(value) =>
									this.handleChange("cashLevelAtStart", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Sales revenue" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.cashFlow.salesRevenue}
								onChange={(value) =>
									this.handleChange("salesRevenue", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Salary" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.cashFlow.Salary}
								onChange={(value) =>
									this.handleChange("Salary", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>

					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Sign On" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.cashFlow.SignOn}
								onChange={(value) =>
									this.handleChange("SignOn", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Training" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.cashFlow.Training}
								onChange={(value) =>
									this.handleChange("Training", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Severance" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.cashFlow.Severance}
								onChange={(value) =>
									this.handleChange("Severance", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Market Entry" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.cashFlow.MarketEntry}
								onChange={(value) =>
									this.handleChange("MarketEntry", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>

					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Marketing" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.cashFlow.Marketing}
								onChange={(value) =>
									this.handleChange("Marketing", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>

					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Lease" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.cashFlow.Lease}
								onChange={(value) =>
									this.handleChange("Lease", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>

					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Overheads" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.cashFlow.Overheads}
								onChange={(value) =>
									this.handleChange("Overheads", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>

					<tr className="autofillFieldBackground">
						<td>
							<div className="bold">
								<Translate
									alias="Forms"
									word="Total expenditures"
								/>
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={totalOverhead}
								decimalSeparator={","}
								disabled
							/>
						</td>
					</tr>

					<tr className="autofillFieldBackground">
						<td>
							<div className="bold">
								<Translate
									alias="Forms"
									word="Free Cash Flow"
								/>
							</div>
						</td>
						<td>
							<FormControl
								aria-label="Small"
								size="sm"
								value={FreeCashFlow}
								disabled
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Investments" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.cashFlow.Investments}
								onChange={(value) =>
									this.handleChange("Investments", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Net Cash Flow" />
							</div>
						</td>
						<td>
							<FormControl
								aria-label="Small"
								size="sm"
								value={NetCashFlow}
								disabled
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Interest" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.cashFlow.Interest}
								onChange={(value) =>
									this.handleChange("Interest", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Taxes" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.cashFlow.Taxes}
								onChange={(value) =>
									this.handleChange("Taxes", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Repayments" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.cashFlow.Repayments}
								onChange={(value) =>
									this.handleChange("Repayments", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>

					<tr className="autofillFieldBackground">
						<td>
							<div className="bold">
								<Translate
									alias="Forms"
									word="Cash level at end of year"
								/>
							</div>
						</td>
						<td>
							<FormControl
								aria-label="Small"
								size="sm"
								value={CashLevel}
								disabled
							/>
						</td>
					</tr>
				</tbody>
			</Table>
		);
	}
}

export default CashFlow;
