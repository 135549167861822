export const initialLabotMarketState = {
	region_1: {
		gold: 0,
		silver: 0,
		green: 0
	},
	region_2: {
		gold: 0,
		silver: 0,
		green: 0
	},
	region_3: {
		gold: 0,
		silver: 0,
		green: 0
	},
	region_4: {
		gold: 0,
		silver: 0,
		green: 0
	}
}
