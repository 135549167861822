import React from "react";
import { Col } from "reactstrap";

import happyFace from "../../assets/images/HappyFace.png";
import sadFace from "../../assets/images/SadFace.png";
import Translate from "../../utils/Translate";

export default function Motivation({ value }) {
	return (
		<Col className="draggable-block  yellowish border-space">
			<img
				src={value >= 0 ? happyFace : sadFace}
				alt="happyFace"
				className="emoji"
			/>
			<input
				type="text"
				value={value > 0 ? `+${value}` : `${value}`}
				readOnly={true}
				className="input-inside yellowish"
				style={{ color: "#1B5B97" }}
			/>
			<p className="sm-header ">
				<Translate alias="Board" word="Motivation" />
				{/* <br />& Self-conﬁdence */}
			</p>
		</Col>
	);
}
