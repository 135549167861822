import React from "react";
import { useDrag, useDrop } from "react-dnd";

// Images
import locationPremium from "../../assets/images/location-premium.png";
import locationBasic from "../../assets/images/location-basic.png";
import EquipmentBasic from "../../assets/images/eq-basic.png";
import EquipmentPremium from "../../assets/images/eq-premium.png";

// Utils
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

// CSS
import "../../assets/css/Style3dModel.css";

export default function DroppableLabel({
	canEdit,
	dropType,
	hasLabel,
	labelType,
	name,
	alias,
	regionIndex,
	basicLblCount,
	premiumLblCount,
}) {
	const [, drop] = useDrop({
		accept: dropType,
		collect: (monitor) => ({
			isOver: monitor.isOver(),
		}),
		drop: (item) => {
			if (item.isFromTeamTable) {
				let dropLbl = false;

				if (item.value === "basic") {
					if (basicLblCount > 0) {
						dropLbl = true;
					}
				} else {
					if (premiumLblCount > 0) {
						dropLbl = true;
					}
				}
				if (labelType === item.value) {
					return { success: false };
				}
				if (dropLbl) {
					const obj = {
						regionIndex: regionIndex,
						alias: alias,
						name: name,
						labelType: item.value,
						value: 1,
					};
					RealtimeConnection.addLabel(obj);
					return { success: true };
				}
			} else {
				const obj = {
					regionIndex: regionIndex,
					alias: alias,
					name: name,
					labelType: item.value,
					value: 1,
				};
				RealtimeConnection.addLabel(obj);

				return { success: true };
			}
		},
	});

	const [, drag] = useDrag({
		item: {
			type: dropType,
			value: labelType,
		},
		end: (item, monitor) => {
			if (monitor.didDrop()) {
				let droppedItem = monitor.getDropResult();
				if (droppedItem !== null && droppedItem.success) {
					const obj = {
						regionIndex: regionIndex,
						alias: alias,
						name: name,
						labelType: item.value,
						value: 1,
					};
					RealtimeConnection.removeLabel(obj);
				}
			}
		},
	});

	const basicImg =
		dropType === items.location ? locationBasic : EquipmentBasic;
	const premiumImg =
		dropType === items.location ? locationPremium : EquipmentPremium;

	let canDrop = canEdit ? drop : null;
	let canDrag = canEdit ? drag : null;
	return (
		<div style={{ height: "35px" }} ref={canDrop}>
			{hasLabel ? (
				<>
					{labelType === "basic" ? (
						<img
							src={basicImg}
							alt="location"
							width="35"
							ref={canDrag}
						/>
					) : (
						<img
							src={premiumImg}
							alt="location"
							width="35"
							ref={canDrag}
						/>
					)}
				</>
			) : (
				<div
					style={{
						width: "30px",
						height: "30px",
						backgroundColor: "#F1F1F1",
						margin: "auto",
						borderRadius: "15px",
					}}
				></div>
			)}
		</div>
	);
}
