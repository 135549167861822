// Add Container
const addContainer = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		alias: options.alias,
		regionIndex: options.regionIndex,
		id: options.name,
		coins: options.coins,
	};

	window.socket.emit("add-container", dataObj);
};

// Remove Container
const removeContainer = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		alias: options.alias,
		regionIndex: options.regionIndex,
		id: options.name,
		coins: options.coins,
	};

	window.socket.emit("remove-container", dataObj);
};

// Add Coin
const addCoin = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		alias: options.alias,
		regionIndex: options.regionIndex,
		id: options.name,
		value: options.value,
	};

	window.socket.emit("add-coin", dataObj);
};

// Remove Coin
const removeCoin = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		alias: options.alias,
		regionIndex: options.regionIndex,
		id: options.name,
		index: options.index,
		value: options.value,
	};

	window.socket.emit("remove-coin", dataObj);
};

// Add Equity
const addEquity = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		value: options.value,
	};

	window.socket.emit("add-equity", dataObj);
};
// Add Token
const addToken = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		value: options.value,
	};

	window.socket.emit("add-token", dataObj);
};

// Remove Token
const removeToken = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		value: options.value,
	};

	window.socket.emit("remove-token", dataObj);
};

// Add Label
const addLabel = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		regionIndex: options.regionIndex,
		alias: options.alias,
		id: options.name,
		labelType: options.labelType,
		value: options.value,
	};

	window.socket.emit("add-label", dataObj);
};

// Remove Label
const removeLabel = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		regionIndex: options.regionIndex,
		alias: options.alias,
		id: options.name,
		labelType: options.labelType,
		value: options.value,
	};

	window.socket.emit("remove-label", dataObj);
};

// Increase team table
const increaseTeamTable = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		type: options.type,
		value: options.value !== undefined ? options.value : 1,
	};

	window.socket.emit("increase-starter-kit", dataObj);
};

// Decrease team table
const decreaseTeamTable = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		type: options.type,
		value: options.value !== undefined ? options.value : 1,
	};

	window.socket.emit("decrease-starter-kit", dataObj);
};
// Decrease Cash
const decreaseCash = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		type: options.type,
		value: options.value,
	};

	window.socket.emit("decrease-cash", dataObj);
};
// increase Cash
const increaseCash = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		type: options.type,
		value: options.value,
	};

	window.socket.emit("increase-cash", dataObj);
};

// Add avatar
const addAvatar = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		...options,
	};

	window.socket.emit("add-avatar", dataObj);
};

const removeAvatar = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		...options,
	};

	window.socket.emit("remove-avatar", dataObj);
};

const addPin = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		regionIndex: options.regionIndex,
		pinNumber: options.pinNumber,
	};

	window.socket.emit("add-pin", dataObj);
};

const removePin = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		regionIndex: options.regionIndex,
		pinNumber: options.pinNumber,
	};

	window.socket.emit("remove-pin", dataObj);
};

// Time
const addTime = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		regionIndex: options.regionIndex,
		alias: options.alias,
		timeIndex: options.timeIndex,
		value: 10,
	};

	window.socket.emit("add-time", dataObj);
};

const subtractTime = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		regionIndex: options.regionIndex,
		alias: options.alias,
		timeIndex: options.timeIndex,
		value: 10,
	};

	window.socket.emit("subtract-time", dataObj);
};

const containerDragging = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		alias: options.alias,
		regionIndex: options.regionIndex,
		id: options.name,
		isDragging: options.isDragging,
	};

	window.socket.emit("container-dragging", dataObj);
};

const coinDragging = (options) => {
	const dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		alias: options.alias,
		regionIndex: options.regionIndex,
		id: options.name,
		isDragging: options.isDragging,
		coinIndex: options.coinIndex,
	};

	window.socket.emit("coin-dragging", dataObj);
};

// Update Team Name
const changeTeamName = (options) => {
	window.socket.emit("change-team", {
		teamId: window.teamId,
		sessionId: window.sessionId,
		value: options.value,
	});
};

// Send Message
const sendMessage = (options) => {
	window.socket.emit("add-message", {
		sessionId: window.sessionId,
		teamId: window.teamId,
		playerId: window.playerId,
		playerName: window.playerName,
		message: options.message,
	});
};
// Change Form Year
const changeFormYear = (options) => {
	window.socket.emit("change-form-year", {
		sessionId: window.sessionId,
		teamId: window.teamId,
		year: options.year,
		type: options.type,
	});
};
// Change
const changeLocationStatus = (options) => {
	window.socket.emit("change-location-status", {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		regionIndex: options.regionIndex,
		coins: options.coins,
		status: options.status,
	});
};

// Planning Sheet
const changePlanningSheet = (options) => {
	const dataObj = {
		sessionId: window.sessionId,
		teamId: window.teamId,
		type: "planningSheet",
		...options,
	};

	window.socket.emit("updateForm", dataObj);
};
// Annual Report
const changeAnnualReport = (options) => {
	const dataObj = {
		sessionId: window.sessionId,
		teamId: window.teamId,
		type: "annualReport",
		year: options.year,
		groupName: options.groupName,
		field: options.field,
		value: options.value,
	};

	window.socket.emit("updateForm", dataObj);
};

const RealtimeConnection = {
	addContainer,
	removeContainer,
	addCoin,
	removeCoin,
	addToken,
	removeToken,
	addLabel,
	removeLabel,
	addAvatar,
	removeAvatar,
	increaseTeamTable,
	decreaseTeamTable,
	addPin,
	removePin,
	addTime,
	subtractTime,
	containerDragging,
	coinDragging,
	changeTeamName,
	sendMessage,
	addEquity,
	changePlanningSheet,
	changeAnnualReport,
	changeFormYear,
	changeLocationStatus,
	increaseCash,
	decreaseCash,
};

export default RealtimeConnection;
