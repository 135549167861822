import React, { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Modal } from "reactstrap";

// Utils
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";
import Translate from "../../utils/Translate";

// Components
import LiabilityModal from "./LiabilityModal";

export default function Liabilities({ canEdit, el, totalTokens }) {
	const [modal, setModal] = useState(false);
	const toggle = () => {
		setModal(!modal);
	};

	const [{ isOver, containerId }, drop] = useDrop({
		accept: items.token,
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			containerId: monitor.getHandlerId(),
		}),
		drop: (item) => {
			if (item.containerId !== containerId) {
				if (totalTokens >= item.value) {
					RealtimeConnection.addToken({
						value: item.value,
					});
	
					return { success: true };
				}
			}
		},
	});

	const [, drag] = useDrag({
		item: {
			type: items.token,
			value: el,
			containerId: containerId,
		},
		end: (item, monitor) => {
			if (monitor.didDrop()) {
				let droppedItem = monitor.getDropResult();
				if (droppedItem !== null && droppedItem.success) {
					const obj = {
						type: "tokens",
						value: item.value,
					};
					RealtimeConnection.removeToken(obj);
				}
			}
		},
	});

	const canDrop = canEdit ? drop : null;
	const canDrag = canEdit ? drag : null;
	return (
		<div
			ref={canDrop}
			className="padding-1"
			style={
				isOver ? { background: "lightgrey" } : { background: "white" }
			}
		>
			{el > 0 ? (
				<>
					<div className="tokenDraggable" ref={canDrag}>
						<div
							className="Token-container-draggable"
							onClick={toggle}
						>
							<div className="Token-value">{el}</div>
							<div className="Token-text">
								<Translate alias="Board" word="liability" />{" "}
							</div>
						</div>
					</div>
					<Modal
						className="ContainerPropertiesPopup"
						isOpen={modal}
						toggle={toggle}
					>
						<LiabilityModal
							canEdit={canEdit}
							tokensValue={el}
							totalTokens={totalTokens}
							toggle={toggle}
						/>
					</Modal>
				</>
			) : (
				<div className="tokenDraggable" />
			)}
		</div>
	);
}
