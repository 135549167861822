import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button } from "reactstrap";

// Utils
import RealtimeConnection from "../../utils/RealtimeConnection";

// CSS
import "../../assets/css/ContainerModal.css";
import Translate from "../../utils/Translate";

export default function ContainerModal({
	canEdit,
	toggle,
	tokensValue,
	totalTokens,
}) {
	const [showAddCashQuestion, setShowAddCashQuestion] = useState(false);
	const [showSubtractCashQuestion, setShowSubtractCashQuestion] =
		useState(false);
	const [tokenValueInput, setTokenValueInput] = useState(0);

	useEffect(() => {
		if (showAddCashQuestion) {
			setShowSubtractCashQuestion(false);
		}
	}, [showAddCashQuestion]);
	useEffect(() => {
		if (showSubtractCashQuestion) {
			setShowAddCashQuestion(false);
		}
	}, [showSubtractCashQuestion]);

	const addTokensAction = () => {
		if (totalTokens >= tokenValueInput) {
			let tokenValue = tokenValueInput;
			tokenValue = Math.ceil(tokenValue);

			setShowAddCashQuestion(false);

			RealtimeConnection.addToken({
				value: tokenValue,
			});
			RealtimeConnection.decreaseTeamTable({
				type: "tokens",
				value: tokenValue,
			});

			toggle();
		}
	};

	const subtractTokensAction = () => {
		if (tokensValue >= tokenValueInput) {
			// let tokenValue = tokenValueInput;
			// tokenValue = Math.ceil(tokenValue * 2) / 2;

			setShowSubtractCashQuestion(false);

			// Update Socket
			RealtimeConnection.removeToken({
				value: tokenValueInput,
			});

			RealtimeConnection.increaseTeamTable({
				type: "tokens",
				value: tokenValueInput,
			});

			toggle();
		}
	};

	return (
		<>
			<div className="liabilityObjClass">
				<div className="tokenDraggable">
					<div className="Token-container-draggable">
						<div className="Token-value">{tokensValue}</div>
						<div className="Token-text">
							<Translate alias="Board" word="liability" />{" "}
						</div>
					</div>
				</div>
			</div>
			<div className="clearfix"></div>
			<div style={{ height: 20 }}></div>
			<div className="horizontal-separator">&nbsp;</div>
			{canEdit && (
				<div className="container-actions">
					<Container>
						<Row>
							<Col xs={1}></Col>
							<Col>
								<Button
									className="purchase-btn"
									onClick={() => setShowAddCashQuestion(true)}
									size="sm"
								>
									<Translate alias="Board" word="Add" />
								</Button>
							</Col>
							<Col xs={2}></Col>
							<Col>
								<Button
									className="rent-btn"
									onClick={() =>
										setShowSubtractCashQuestion(true)
									}
									size="sm"
								>
									<Translate alias="Board" word="Subtract" />
								</Button>
							</Col>
							<Col xs={1}></Col>
						</Row>
						<Row>
							<Col className="questions-container">
								{showAddCashQuestion ? (
									<>
										<div className="question-text">
											<Translate
												alias="Board"
												word="How much cash do you want to add?"
											/>
										</div>
										<div className="question-input">
											<input
												type="text"
												autoFocus
												id="units"
												pattern="^-?[0-9]\d*\.?\d*$"
												name="units"
												onKeyPress={(event) => {
													if (event.key === "Enter") {
														addTokensAction();
													}
												}}
												onChange={(e) =>
													setTokenValueInput(
														parseInt(e.target.value)
													)
												}
											/>
										</div>
										<div className="question-btn">
											<Button
												className="purchase-btn"
												size="sm"
												onClick={() =>
													addTokensAction()
												}
											>
												<Translate
													alias="Board"
													word="Confirm"
												/>
											</Button>
										</div>
									</>
								) : null}
								{showSubtractCashQuestion ? (
									<>
										<div className="question-text">
											<Translate
												alias="Board"
												word="How much cash do you want to subtract?"
											/>
										</div>
										<div className="question-input">
											<input
												type="text"
												autoFocus
												id="units2"
												pattern="^-?[0-9]\d*\.?\d*$"
												name="units2"
												onKeyPress={(event) => {
													if (event.key === "Enter") {
														subtractTokensAction();
													}
												}}
												onChange={(e) =>
													setTokenValueInput(
														parseInt(e.target.value)
													)
												}
											/>
										</div>
										<div className="question-btn">
											<Button
												className="purchase-btn"
												size="sm"
												onClick={() =>
													subtractTokensAction()
												}
											>
												<Translate
													alias="Board"
													word="Confirm"
												/>
											</Button>
										</div>
									</>
								) : null}
							</Col>
						</Row>
					</Container>
				</div>
			)}
		</>
	);
}
