import React, { useState, useEffect, useRef } from "react";

import Linkify from "react-linkify";

// CSS
import "../../assets/css/Chat.css";

import RealtimeConnection from "../../utils/RealtimeConnection";
import Translate from "../../utils/Translate";

const ChatComponent = ({ closeChat, messages }) => {

	const messageEl = useRef(undefined)
	const [message, setMessage] = useState("")
	const [messagesList, setMessagesList] = useState([])

	const scrollToBottom = () => {
		if (messageEl) {
			const scrollHeight = messageEl.current.scrollHeight;
			const height = messageEl.current.clientHeight;
			const maxScrollTop = scrollHeight - height;
			messageEl.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
		}
	}

	useEffect(() => {
		scrollToBottom()
	}, [])

	useEffect(() => {
		if (messages.length !== messagesList.length) {
			setMessagesList(messages)
			setTimeout(() => {
				scrollToBottom()
			}, 500)
		}
	}, [messages])

	useEffect(() => {
		if (message === "") {
			resizeFrame();
		}
	}, [message])

	const sendMessage = () => {
		if (message !== "") {
			// Update Socket
			RealtimeConnection.sendMessage({
				message: message,
			});

			setMessage("")
			scrollToBottom()
		}
	};

	const resizeFrame = () => {
		let msg = document.getElementById("messageTxtArea");
		let div = document.getElementsByClassName("form-container")[0];

		setTimeout(function () {
			msg.style.cssText = "height:0px";
			let height = Math.min(20 * 5, msg.scrollHeight);
			if (height < 30) {
				height = 30;
			}
			div.style.cssText = "height:" + (height + 10) + "px";
			msg.style.cssText = "height:" + height + "px";
		}, 0);
	};

	return (
		<div className="chat-container">
			<div className="chat-popup" id="myForm">
				<div className="chat-popup-header">
					<div className="chat-popup-header-title">
						<Translate alias="Board" word="Messages" />
					</div>
					<div className="chat-popup-header-btn">
						<button type="button" onClick={closeChat}>
							X
						</button>
					</div>
				</div>
				<div
					id="MessagesList"
					className="chat-messages"
					ref={messageEl}
				>
					{ 
						messagesList ? 
							messagesList.map((obj, index) => {
								return (
									<div key={index} className="w-100 d-inline-block">
										<div
											className="chat-message"
											style={
												window.playerId ===
												obj.playerId
													? {
															float: "right",
															background: "rgb(27, 91, 151,0.2)"
														}
													: { float: "left" }
											}
										>
											<span
												className="playerName"
												style={
													window.playerId ===
													obj.playerId
														? {
																textAlign: "right"
															}
														: {
																textAlign: "left"
															}
												}
											>
												{ obj.playerName }
											</span>
											<div className="message">
												<Linkify properties={{ target: "_blank" }}>
													{obj.text}
												</Linkify>
											</div>
										</div>
									</div>
								);
							})
							: 
							null
					}
				</div>
				<div className="chat-form">
					<div className="form-container">
						<textarea
							id="messageTxtArea"
							placeholder="Type message.."
							name="msg"
							rows="1"
							required
							value={message}
							onKeyPress={(event) => {
								if (event.key === "Enter" && !event.shiftKey) {
									sendMessage();
									event.preventDefault();
									event.stopPropagation();
								} else {
									resizeFrame();
								}
								return true;
							}}
							onChange={(event) => {
								setMessage(event.target.value)
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	)

}

export default ChatComponent;
