export const initialBoardState = {
	leftBlock: [
		{
			name: "Marketing",
			hasContainer: false,
			coins: [],
		},
		{
			name: "Market Entry",
			hasContainer: false,
			coins: [],
		},
		{
			name: "Interest",
			hasContainer: false,
			coins: [],
		},
		{
			name: "Sign On",
			hasContainer: false,
			coins: [],
		},
		{
			name: "Salary",
			hasContainer: false,
			coins: [],
		},
		{
			name: "Taxes",
			hasContainer: false,
			coins: [],
		},
	],
	rightBlock: [
		{
			name: "Training",
			hasContainer: false,
			coins: [],
		},
		{
			name: "Severance",
			hasContainer: false,
			coins: [],
		},
		{
			name: "Overheads",
			hasContainer: false,
			coins: [],
		},
		{
			name: "Depreciation",
			hasContainer: false,
			coins: [],
		},
		{
			name: "Lease",
			hasContainer: false,
			coins: [],
		},
	],
	cash: [
		{
			name: 0,
			hasContainer: false,
			coins: [],
		},
		{
			name: 1,
			hasContainer: false,
			coins: [],
		},
		{
			name: 2,
			hasContainer: false,
			coins: [],
		},
		{
			name: 3,
			hasContainer: false,
			coins: [],
		},
		{
			name: 4,
			hasContainer: false,
			coins: [],
		},
		{
			name: 5,
			hasContainer: false,
			coins: [],
		},
		{
			name: 6,
			hasContainer: false,
			coins: [],
		},
		{
			name: 7,
			hasContainer: false,
			coins: [],
		},
	],
	liability: 0,
	equity: 0,
	regions: [
		{
			id: 1,
			pins: [],
			customer: {
				"SALES VOLUME": "",
				STAFFING: 0,
				Requirements: "",
			},
			yellow: {
				max: 3,
				count: 0,
				list: [],
			},
			grey: {
				max: 9,
				count: 0,
				list: [],
			},
			green: {
				max: 24,
				count: 0,
				white: 0,
				list: [],
				whiteList: [],
			},
			Motivation: 0,
			containers: [
				{
					name: "Location",
					hasContainer: false,
					coins: [],
					status: "premium",
					rented: false,
				},
				{
					name: "Equipment",
					hasContainer: false,
					coins: [],
					status: "basic",
				},
			],
			time: {
				top: [
					{
						name: "Strategic Tasks",
						value: 0,
					},
					{
						name: "Daily Tasks",
						value: 0,
						customClass: "border-space",
					},
					{
						name: "Leading People",
						value: 0,
					},
					{
						name: "Buffer",
						value: 0,
						customClass: "border-space",
					},
				],
				bottom: [
					{
						name: "Customers",
						value: 0,
					},
					{
						name: "Leading People",
						value: 0,
					},
				],
			},
		},
		{
			id: 2,
			pins: [{ hasPin: false }],
			customer: {
				"SALES VOLUME": "",
				STAFFING: 0,
				Requirements: "",
			},
			yellow: {
				max: 1,
				count: 0,
				list: [],
			},
			grey: {
				max: 3,
				count: 0,
				list: [],
			},
			green: {
				max: 24,
				count: 0,
				white: 0,
				list: [],
				whiteList: [],
			},
			Motivation: 0,
			containers: [
				{
					name: "Location",
					hasContainer: false,
					coins: [],
					status: "premium",
					rented: false,
				},
				{
					name: "Equipment",
					hasContainer: false,
					coins: [],
					status: "basic",
				},
			],
			time: {
				top: [
					{
						name: "Strategic Tasks",
						value: 0,
					},
					{
						name: "Daily Tasks",
						value: 0,
						customClass: "border-space",
					},
					{
						name: "Leading People",
						value: 0,
					},
					{
						name: "Buffer",
						value: 0,
						customClass: "border-space",
					},
				],
				bottom: [
					{
						name: "Customers",
						value: 0,
					},
					{
						name: "Leading People",
						value: 0,
					},
				],
			},
		},
		{
			id: 4,
			pins: [{ hasPin: false }, { hasPin: false }],
			customer: {
				"SALES VOLUME": "",
				STAFFING: 0,
				Requirements: "",
			},
			yellow: {
				max: 2,
				count: 0,
				list: [],
			},
			grey: {
				max: 6,
				count: 0,
				list: [],
			},
			green: {
				max: 24,
				count: 0,
				white: 0,
				list: [],
				whiteList: [],
			},
			Motivation: 0,
			containers: [
				{
					name: "Location",
					hasContainer: false,
					coins: [],
					status: "premium",
					rented: false,
				},
				{
					name: "Equipment",
					hasContainer: false,
					coins: [],
					status: "basic",
				},
			],
			time: {
				top: [
					{
						name: "Strategic Tasks",
						value: 0,
					},
					{
						name: "Daily Tasks",
						value: 0,
						customClass: "border-space",
					},
					{
						name: "Leading People",
						value: 0,
					},
					{
						name: "Buffer",
						value: 0,
						customClass: "border-space",
					},
				],
				bottom: [
					{
						name: "Customers",
						value: 0,
					},
					{
						name: "Leading People",
						value: 0,
					},
				],
			},
		},
		{
			id: 3,
			pins: [{ hasPin: false }, { hasPin: false }],
			customer: {
				"SALES VOLUME": "",
				STAFFING: 0,
				Requirements: "",
			},
			yellow: {
				max: 2,
				count: 0,
				list: [],
			},
			grey: {
				max: 6,
				count: 0,
				list: [],
			},
			green: {
				max: 24,
				count: 0,
				white: 0,
				list: [],
				whiteList: [],
			},
			Motivation: 0,
			containers: [
				{
					name: "Location",
					hasContainer: false,
					coins: [],
					status: "premium",
					rented: false,
				},
				{
					name: "Equipment",
					hasContainer: false,
					coins: [],
					status: "basic",
				},
			],
			time: {
				top: [
					{
						name: "Strategic Tasks",
						value: 0,
					},
					{
						name: "Daily Tasks",
						value: 0,
						customClass: "border-space",
					},
					{
						name: "Leading People",
						value: 0,
					},
					{
						name: "Buffer",
						value: 0,
						customClass: "border-space",
					},
				],
				bottom: [
					{
						name: "Customers",
						value: 0,
					},
					{
						name: "Leading People",
						value: 0,
					},
				],
			},
		},
	],
};
