import React from "react";
import { useDrag } from "react-dnd";

// Images
import basicImg from "../../assets/images/eq-basic.png";
import premiumImg from "../../assets/images/eq-premium.png";

// Utils
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection"

export default function MainDraggableEquipment({ canEdit, value }) {
	const [, drag] = useDrag({
		item: {
			type: items.equipment,
			isFromTeamTable: true,
			value: value
		},
		end: (item, monitor) => {
			if (monitor.didDrop()) {
				let droppedItem = monitor.getDropResult();
				if (droppedItem !== null && droppedItem.success) {
					const obj = {
						type: (value === "basic") ? "standardEquipment" : "premiumEquipment",
						value: 1
					}
					RealtimeConnection.decreaseTeamTable(obj)
				}
			}
		},
	});

	const canDrag = (canEdit) ? drag : null
	return (
		<>
			<div className="draggable-coins-container" ref={canDrag}>
				<img alt="draggable-coins" className="draggable-coins" src={ (value === "basic") ? basicImg : premiumImg } />
			</div>
		</>
	);
}
