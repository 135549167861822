const sessionState = {
	files: [],
	isChatOpened: false,
	currentEventId: undefined,
	messages: [],
	allowTimeAllocation: false,
	checkList: {},
	selectedLang: "en",
	formSelectedYear: 0,
	year: 0,
	formStatus: {
		planningSheet: false,
		annualReport: false,
	},
};

export default sessionState;
