import React from "react";
import circle1 from "../../assets/SVG/Asset 4.svg";
import circle3 from "../../assets/SVG/Asset 6.svg";
import circle2 from "../../assets/SVG/Asset 5.svg";
import smiley from "../../assets/SVG/Asset 1.svg";

import green from "../../assets/images/avatar.png";

import { Row, Col } from "reactstrap";
import Time from "../CenterComponents/Time";
import Motivation from "../CenterComponents/Motivation";
import Equipment from "../CenterComponents/Equipment";
import Location from "../CenterComponents/Location";
import AvatarContainer from "../CenterComponents/AvatarContainer";
import PinContainer from "../CenterComponents/PinContainer";
import Translate from "../../utils/Translate";

export default function RegionBlock({
	canEdit,
	cashCoinsCount,
	state,
	dispatch,
	region,
	index,
	allowTimeAllocation,
}) {
	return (
		<Col className="single-region-block">
			<Col className="side-block p-1">
				{/* --------top-block------------- */}
				<div className="top-block">
					<p className="side-blocks-title">
						<Translate alias="Board" word="MY EMPLOYEES" />
					</p>
					<p
						className="side-blocks-title r-2"
						style={{ position: "absolute", textAlign: "end" }}
					>
						<Translate alias="Board" word="DIRECTORS" />
					</p>
					<div className="d-flex flex-column">
						<img
							src={circle1}
							alt="circle1"
							className="sm-img mb-1"
						/>
						<img src={circle2} alt="circle2" className="sm-img" />
					</div>
					<Row xs={2} className="side-blocks" noGutters={true}>
						{region["time"]["top"].map((el, timeIndex) => (
							<Time
								key={timeIndex}
								canEdit={canEdit}
								el={el}
								timesList={region.time.top}
								regionIndex={index}
								timeIndex={timeIndex}
								dispatch={dispatch}
								timeCount={state.timeCount}
								alias={"top"}
								allowTimeAllocation={allowTimeAllocation}
							/>
						))}
					</Row>
				</div>
				<div className="white-line" />

				{/* --------bottom-block------------- */}
				<div className="bottom-block">
					<p className="side-blocks-title">
						<Translate alias="Board" word="MY EMPLOYEES" />
					</p>
					<p
						className="side-blocks-title r-2"
						style={{ position: "absolute", textAlign: "end" }}
					>
						<Translate alias="Board" word="MANAGERS" />
					</p>
					<div className="d-flex flex-column">
						<img
							src={circle3}
							alt="circle3"
							className="sm-img mb-1"
						/>
						<div className="d-flex">
							<img
								src={circle2}
								alt="circle2"
								className="sm-img"
							/>
							<img src={smiley} alt="smiley" className="sm-img" />
						</div>
					</div>
					<Row xs={2} className="side-blocks" noGutters={true}>
						<Time
							canEdit={canEdit}
							el={region["time"]["bottom"][0]}
							timesList={region.time.bottom}
							timeIndex={0}
							regionIndex={index}
							dispatch={dispatch}
							timeCount={state.timeCount}
							alias={"bottom"}
							allowTimeAllocation={allowTimeAllocation}
						/>

						<Motivation value={region["Motivation"]} />
						<Time
							canEdit={canEdit}
							el={region["time"]["bottom"][1]}
							timesList={region.time.bottom}
							timeIndex={1}
							regionIndex={index}
							dispatch={dispatch}
							timeCount={state.timeCount}
							alias={"bottom"}
							allowTimeAllocation={allowTimeAllocation}
						/>
					</Row>
				</div>
			</Col>
			{/* ----------Region------------- */}
			<Row className="region-block p-1" noGutters={true}>
				<Col style={{ position: "relative" }} className="pr-1">
					<div
						className="d-flex region-id"
						style={{ position: "absolute", width: "130%" }}
					>
						<p className="region-block-title text-white">
							<Translate alias="Board" word="REGION" /> {`${region.id}`}
						</p>
						<div className="d-flex">
							{region.pins.map((e, i) => (
								<PinContainer
									key={i}
									canEdit={canEdit}
									el={e}
									pinNumber={i}
									regionIndex={index}
								/>
							))}
						</div>
					</div>
					<Col className="customer-block p-1 pt-3">
						<div className="d-flex flex-column text-center liability h-100 justify-content-center whitish">
							<p
								className="sm-header"
								style={{ marginTop: "-16px" }}
							>
								<Translate alias="Board" word="Customer" />
							</p>
							<span
								style={{ fontSize: "1.2vw" }}
								className="py-2"
							>
								{region["customer"]["SALES VOLUME"]}
							</span>
							<span
								style={{ fontSize: " 0.6vw" }}
								className="light-bluish"
							>
								{" "}
								<Translate alias="Board" word="SALES VOLUME" />
							</span>
							<div className="kits-separator" />
							<span
								style={{ fontSize: " 1.2vw" }}
								className="py-2"
							>
								{region["customer"]["STAFFING"] !== 1
									? `${region["customer"]["STAFFING"]}x`
									: region["customer"]["STAFFING"]}{" "}
								&nbsp;
								<img
									src={green}
									alt="green"
									className="sm-img"
								/>
							</span>
							<span
								style={{ fontSize: " 0.6vw" }}
								className="light-bluish"
							>
								{" "}
								<Translate alias="Board" word="STAFFING" />
							</span>
						</div>
					</Col>
				</Col>

				<Col className="flex-grow-0">
					<Row
						className="mt-2 p-0"
						noGutters={true}
						style={{ marginLeft: "-20px" }}
					>
						<AvatarContainer
							canEdit={canEdit}
							el={region["yellow"]}
							color={"yellow"}
							regionIndex={index}
							avatarCount={state["TeamTable"]["goldAvatar"]}
							currentYear={state.Session.year}
						/>
						<AvatarContainer
							canEdit={canEdit}
							el={region["grey"]}
							color={"grey"}
							regionIndex={index}
							avatarCount={state["TeamTable"]["silverAvatar"]}
							currentYear={state.Session.year}
						/>
						<AvatarContainer
							canEdit={canEdit}
							el={region["green"]}
							color={"green"}
							regionIndex={index}
							avatarCount={state["TeamTable"]["greenAvatar"]}
							currentYear={state.Session.year}
						/>

						<Row
							className="p-0 w-100 justify-content-around"
							noGutters={true}
						>
							<Location
								canEdit={canEdit}
								el={region["containers"][0]}
								regionIndex={index}
								alias={"containers"}
								index={0}
								dispatch={dispatch}
								cashCoinsCount={cashCoinsCount}
								rented={
									state.Board.regions[index][
										"containers"
									].find((x) => x.name === "Location").rented
								}
								coinsCount={state.TeamTable.coins}
								basicLocationLblCount={
									state.TeamTable.standardLocation
								}
								premiumLocationLblCount={
									state.TeamTable.premiumLocation
								}
							/>
							<Equipment
								canEdit={canEdit}
								el={region["containers"][1]}
								regionIndex={index}
								alias={"containers"}
								index={1}
								dispatch={dispatch}
								coinsCount={state.TeamTable.coins}
								cashCoinsCount={cashCoinsCount}
								basicEquipmentLblCount={
									state.TeamTable.standardEquipment
								}
								premiumEquipmentLblCount={
									state.TeamTable.premiumEquipment
								}
							/>
						</Row>
					</Row>
				</Col>
			</Row>
		</Col>
	);
}
