import React from "react";
import { useDrop } from "react-dnd";

// Utils
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

// Components
import DraggablePin from "./DraggablePin";

export default function PinContainer({ canEdit, el, pinNumber, regionIndex }) {
	const [{ isOver }, drop] = useDrop({
		accept: items.pin,
		collect: (monitor) => ({
			isOver: monitor.isOver(),
		}),
		drop: (item) => {
			if (!el.hasPin) {
				const obj = {
					regionIndex: regionIndex,
					pinNumber: pinNumber,
				};
				RealtimeConnection.addPin(obj);

				return { success: true };
			}
		},
	});

	const canDrop = (canEdit) ? drop : null
	return (
		<div
			ref={canDrop}
			className="square mx-1"
			style={isOver ? { opacity: "0.7" } : { opacity: "1" }}
		>
			<DraggablePin
				canEdit={ canEdit }
				e={el}
				regionIndex={regionIndex}
				pinNumber={pinNumber}
			/>
		</div>
	);
}
