import React from "react";
import { DragPreviewImage, useDrag } from "react-dnd";
import { Col } from "react-bootstrap";

// Images
import pin from "../../assets/images/pushpin2.png";
import previewImg from "../../assets/images/pushpin-preview.png";

// Utils
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection"

export default function MainDraggablePin({ canEdit }) {
	const [, drag, preview] = useDrag({
		item: { type: items.pin },
		end: (item, monitor) => {
			if (monitor.didDrop()) {
				let droppedItem = monitor.getDropResult();
				if (droppedItem !== null && droppedItem.success) {
					console.log(droppedItem)

					const obj = {
						type: "pins",
						value: 1
					}
					RealtimeConnection.decreaseTeamTable(obj)

					return { success: true }
				}
			}
		},
	});

	const canDrag = (canEdit) ? drag : null
	return (
		<Col xs={2}>
			<DragPreviewImage connect={preview} src={previewImg} />
			<div className="pinDraggable" ref={canDrag}>
				<img alt="pin" src={pin} />
			</div>
		</Col>
	);
}
