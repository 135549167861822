import React, { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Modal } from "reactstrap";

// Images
import container from "../../assets/images/container-h.png";
import containerFlipped from "../../assets/images/box-container-flipped.png";

// Utils
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

// CSS
import "../../assets/css/Style3dModel.css";

// Components
import DraggableCoin from "./DraggableCoin";
import ContainerModal from "../CenterComponents/ContainerModal";

let sentCount = 0;

export default function DroppableContainer({
	canEdit,
	playerId,
	cashCoinsCount,
	depreciation,
	isObjectDragging,
	draggingObject,
	dragCoinIndex,
	hasContainer,
	name,
	fieldIndex,
	alias,
	dispatch,
	coins,
	coinsCount,
	regionIndex,
	location,
	rented,
	equipment,
	containerDrop,
	cash,
}) {
	const [modal, setModal] = useState(false);
	const [isShown, setIsShown] = useState(false);
	const toggle = () => {
		if (hasContainer) setModal(!modal); // && !cash
	};

	const [{ isDragging }, drag] = useDrag({
		item: { type: items.container, coins: coins },
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (item, monitor) => {
			if (monitor.didDrop()) {
				let droppedItem = monitor.getDropResult();
				if (droppedItem !== null && droppedItem.success) {
					const obj = {
						alias: alias,
						name: fieldIndex,
						regionIndex: regionIndex,
						coins: item.coins ? item.coins : [],
					};
					RealtimeConnection.removeContainer(obj);
				}
			}
		},
	});

	const [{ containerId }, drop] = useDrop({
		accept: items.coin,
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
			containerId: monitor.getHandlerId(),
		}),
		drop: (item, monitor) => {
			if (monitor.getHandlerId() === item.containerId) {
				return {};
			}

			if (coins.length < 20) {
				if (item.isFromTeamTable) {
					if (coinsCount >= item.value) {
						const obj = {
							name: fieldIndex,
							regionIndex: regionIndex,
							alias: alias,
							value: item.value,
						};
						RealtimeConnection.addCoin(obj);

						return { success: true };
					}
				} else {
					const obj = {
						name: fieldIndex,
						regionIndex: regionIndex,
						alias: alias,
						value: item.value,
					};
					RealtimeConnection.addCoin(obj);

					return { success: true };
				}
			}
		},
	});

	let canPlayerDrag = canEdit ? drag : null;
	let canPlayerDrop = canEdit ? drop : null;

	if (isDragging) {
		if (window.playerId !== playerId && sentCount === 0) {
			sentCount++;
			const obj = {
				name: fieldIndex,
				regionIndex: regionIndex,
				alias: alias,
				isDragging: true,
			};
			RealtimeConnection.containerDragging(obj);
		}
	} else if (isObjectDragging && draggingObject === "CONTAINER") {
		canPlayerDrag = null;
		canPlayerDrop = null;
	} else {
		if (sentCount > 0) {
			sentCount = 0;

			const obj = {
				name: fieldIndex,
				regionIndex: regionIndex,
				alias: alias,
				isDragging: false,
			};
			RealtimeConnection.containerDragging(obj);
		}
	}

	let canDrop = hasContainer ? null : containerDrop;
	let canPlayerDropp = rented ? null : canPlayerDrop;
	const style = {
		display: hasContainer ? "block" : "contents",
		opacity:
			isDragging || (isObjectDragging && draggingObject === "CONTAINER")
				? 0.5
				: 1,
	};

	return (
		<div ref={canPlayerDrag} style={style} key={name + fieldIndex}>
			<div className={`rectangle ${name}`} ref={canDrop} onClick={toggle}>
				{hasContainer ? (
					<>
						<div
							className="coins-sum"
							style={
								isShown && coins.reduce((a, b) => a + b, 0) > 0
									? { display: "block" }
									: { display: "none" }
							}
						>
							{coins.reduce((a, b) => a + b, 0)}
						</div>

						{rented ? (
							<img
								src={containerFlipped}
								alt="container"
								width="100%"
								height="100%"
							/>
						) : (
							<img
								src={container}
								alt="container"
								width="100%"
								height="100%"
							/>
						)}
						<div
							className="Coins-droppable"
							ref={canPlayerDropp}
							onMouseEnter={() => setIsShown(true)}
							onMouseLeave={() => setIsShown(false)}
						>
							<span className="helper" />
							{coins.map((value, index) => (
								<DraggableCoin
									key={index}
									containerId={containerId}
									value={value}
									regionIndex={regionIndex}
									dispatch={dispatch}
									name={fieldIndex}
									alias={alias}
									index={index}
									isLastCoin={
										coins.length === index + 1 &&
										(index + 1) % 5 === 0
											? true
											: false
									}
									playerId={playerId}
									isObjectDragging={isObjectDragging}
									draggingObject={draggingObject}
									dragCoinIndex={dragCoinIndex}
								/>
							))}
						</div>
					</>
				) : null}
			</div>
			<Modal
				className="ContainerPropertiesPopup"
				isOpen={modal}
				toggle={toggle}
			>
				<ContainerModal
					canEdit={canEdit}
					dispatch={dispatch}
					coins={coins}
					cashCoinsCount={cashCoinsCount}
					depreciation={depreciation}
					coinsCount={coinsCount}
					fieldIndex={fieldIndex}
					alias={alias}
					regionIndex={regionIndex}
					toggle={toggle}
					location={location}
					equipment={equipment}
					rented={rented}
				/>
			</Modal>
		</div>
	);
}
