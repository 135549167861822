import React from "react";
import { DragPreviewImage, useDrag } from "react-dnd";

// Images
import boxImg from "../../assets/images/draggable-container.png";
import previewImg from "../../assets/images/draggable-container-preview.png";
import items from "../../utils/items";

export default function MainDraggableContainer(props) {
  const [, drag, preview] = useDrag({
    item: { type: items.container },
  });

  const canDrag = (props.canEdit) ? drag : null
  return (
    <>
      <DragPreviewImage className="dragging" connect={preview} src={previewImg} />
      <div className="containerDraggable" ref={canDrag}>
        <img alt="boxImg" src={boxImg} />
      </div>
    </>
  );
}
