import React from "react";
import { Table, FormControl } from "react-bootstrap";
import InputNumber from "rc-input-number";
import RealtimeConnection from "../../../utils/RealtimeConnection";
import Translate from "../../../utils/Translate";

class BalanceSheet extends React.Component {
	handleChange(field, value) {
		if (this.props.canEdit) {
			RealtimeConnection.changeAnnualReport({
				year: this.props.year,
				groupName: "balanceSheet",
				field: field,
				value: value,
			});
		}
	}

	render() {
		let totalAssets = 0;
		totalAssets += !isNaN(parseFloat(this.props.balanceSheet.Locations))
			? parseFloat(this.props.balanceSheet.Locations)
			: 0;
		totalAssets += !isNaN(parseFloat(this.props.balanceSheet.Equipments))
			? parseFloat(this.props.balanceSheet.Equipments)
			: 0;
		totalAssets += !isNaN(parseFloat(this.props.balanceSheet.cash))
			? parseFloat(this.props.balanceSheet.cash)
			: 0;

		let totalLiabilities = 0;
		totalLiabilities += !isNaN(parseFloat(this.props.balanceSheet.equity))
			? parseFloat(this.props.balanceSheet.equity)
			: 0;

		totalLiabilities += !isNaN(
			parseFloat(this.props.balanceSheet.liabilities)
		)
			? parseFloat(this.props.balanceSheet.liabilities)
			: 0;

		return (
			<Table bordered>
				<thead>
					<tr>
						<th colSpan="2">
							<div className="bold">
								<Translate alias="Forms" word="Assets" />
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Locations" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.balanceSheet.Locations}
								onChange={(value) =>
									this.handleChange("Locations", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Equipments" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.balanceSheet.Equipments}
								onChange={(value) =>
									this.handleChange("Equipments", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">
								<Translate
									alias="Forms"
									word="Cash and cash equivalents"
								/>
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.balanceSheet.cash}
								onChange={(value) =>
									this.handleChange("cash", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>

					<tr className="autofillFieldBackground">
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Total assets" />
							</div>
						</td>
						<td>
							<FormControl
								aria-label="Small"
								size="sm"
								value={totalAssets}
								disabled
							/>
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
				</tbody>
				<thead>
					<tr>
						<th colSpan="2">
							<div className="bold">
								<Translate
									alias="Forms"
									word="Equity and Liabilities"
								/>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<div className="bold">
								<Translate
									alias="Forms"
									word="Capital/Equity"
								/>
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.balanceSheet.equity}
								onChange={(value) =>
									this.handleChange("equity", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>

					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Liabilities" />
							</div>
						</td>
						<td>
							<InputNumber
								className="annualField"
								value={this.props.balanceSheet.liabilities}
								onChange={(value) =>
									this.handleChange("liabilities", value)
								}
								decimalSeparator={","}
								disabled={!this.props.canEdit}
							/>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold">
								<Translate
									alias="Forms"
									word="Total equity and liabilities"
								/>
							</div>
						</td>
						<td>
							<FormControl
								aria-label="Small"
								size="sm"
								value={totalLiabilities}
								disabled
							/>
						</td>
					</tr>
				</tbody>
			</Table>
		);
	}
}

export default BalanceSheet;
