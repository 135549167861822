import React from "react";
import { useDrag } from "react-dnd";

// Images
import grey from "../../assets/images/avatar-1.png";
import yellow from "../../assets/images/avatar-2.png";
import green from "../../assets/images/avatar.png";
import white from "../../assets/images/white-avatar.png";

// Utils
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

export default function DraggablePin({
	canEdit,
	color,
	index,
	regionIndex,
	characterDetails,
	currentYear,
	park,
}) {
	const [, drag] = useDrag({
		item: {
			type: items[color],
			color: color,
			regionIndex: regionIndex && regionIndex,
			hiredYear: characterDetails && characterDetails.hiredYear,
			index: index,
			park: park,
		},
		end: (item, monitor) => {
			if (monitor.didDrop()) {
				let droppedItem = monitor.getDropResult();
				if (droppedItem !== null && droppedItem.success) {
					let obj = {};
					if (park) {
						obj = {
							color: item.color,
							index: item.index,
							alias: "park",
						};
					} else {
						obj = {
							regionIndex: regionIndex,
							color: item.color,
							index: item.index,
						};
					}
					RealtimeConnection.removeAvatar(obj);
				}
			}
		},
	});

	const canDrag = canEdit ? drag : null;

	let previewImg = white;
	switch (color) {
		case "grey":
			previewImg = grey;
			break;
		case "yellow":
			previewImg = yellow;
			break;
		case "green":
			previewImg = green;
			break;
		default:
			break;
	}

	let avatarMark =
		characterDetails && characterDetails.hiredYear === currentYear
			? "yellowBorder"
			: "";
	if (
		characterDetails &&
		characterDetails.lastRegion !== -1 &&
		characterDetails.lastRegion !== regionIndex
	) {
		avatarMark = "redBorder";
	}

	return (
		<>
			<img
				src={previewImg}
				alt={color}
				className={`avatar-pic ${avatarMark}`}
				ref={canDrag}
			/>
		</>
	);
}
