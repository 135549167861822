import React from "react";
import { useDrop } from "react-dnd";
import items from "../../utils/items";
import DroppableContainer from "../draggable/DroppableContainer";

import RealtimeConnection from "../../utils/RealtimeConnection";

export default function Cash({
	canEdit,
	cashCoinsCount,
	playerId,
	isObjectDragging,
	draggingObject,
	coinIndex,
	state,
	dispatch,
	alias,
	hasContainer,
	coins,
	index,
}) {
	const [{ isOver }, drop] = useDrop({
		accept: items.container,
		collect: (monitor) => ({
			isOver: monitor.isOver(),
		}),
		drop: (item) => {
			const obj = {
				alias: alias,
				name: index,
				coins: item.coins ? item.coins : [],
			};
			RealtimeConnection.addContainer(obj);
			return { success: true };
		},
	});

	const canDrop = canEdit ? drop : null;
	return (
		<>
			<div className="border">
				<DroppableContainer
					canEdit={canEdit}
					cashCoinsCount={cashCoinsCount}
					containerDrop={canDrop}
					containerIsOver={isOver}
					hasContainer={hasContainer}
					name={index}
					depreciation={ true }
					fieldIndex={index}
					alias={alias}
					dispatch={dispatch}
					coins={coins}
					coinsCount={state.TeamTable.coins}
					playerId={playerId}
					isObjectDragging={isObjectDragging}
					draggingObject={draggingObject}
					dragCoinIndex={coinIndex}
					cash={true}
				/>
			</div>
		</>
	);
}
