import React, { useState } from "react";
import "../../assets/css/FixedBars.css";
import kitsIcon from "../../assets/images/kits-icon.png";
import MainDraggableContainer from "../draggable/MainDraggableContainer";
import MainDraggableCoin from "../draggable/MainDraggableCoin";
import { Col, Row } from "reactstrap";

import MainDraggablePin from "../draggable/MainDraggablePin";
import MainDraggableAvatar from "../draggable/MainDraggableAvatars";
import MainDroppableDelete from "../draggable/MainDroppableDelete";
//import MainDraggableMotivation from "./draggable/MainDraggableMotivation";
//import MainDraggableTime from "./draggable/MainDraggableTime";
import MainDraggableLiability from "../draggable/MainDraggableLiability";
import MainDraggableLocation from "../draggable/MainDraggableLocation";
import MainDraggableEquipment from "../draggable/MainDraggableEquipment";
import Translate from "../../utils/Translate";

export default function FixedLeftBar({ canEdit, state, dispatch }) {
	const [isMenuOpened, setMenuOpened] = useState(false);

	function updateUI(panelType, status) {
		const appContainer =
			document.getElementsByClassName("center-container");
		const menuContainer = document.getElementsByClassName("kitContainer");

		if (status === "close") {
			appContainer[0].classList.remove("moveLeft");
			menuContainer[0].classList.remove("fullWidth");
		} else {
			appContainer[0].classList.remove("moveRight");
			appContainer[0].classList.add("moveLeft");

			if (panelType === "MENU") {
				menuContainer[0].classList.add("fullWidth");
			} else {
				setMenuOpened(false);
			}
		}
	}
	function toggleMenu() {
		if (isMenuOpened) {
			setMenuOpened(false);
			updateUI("MENU", "close");
		} else {
			setMenuOpened(true);
			updateUI("MENU", "open");
		}
	}
	return (
		<>
			<div className="kits-objects-btn" onClick={toggleMenu}>
				<div className="button">
					<img alt="kits-icon" src={kitsIcon} />
					<div
						className={`text ${
							parseInt(localStorage.langId) === 46
								? "transform"
								: ""
						}`}
					>
						<Translate alias="Board" word="Team Table" />
					</div>
				</div>
			</div>
			<div className="kitContainer">
				<div className="kits-header">
					<div className="kits-header-logo">
						<img alt="kits-icon" src={kitsIcon} />
						<div className="text">
							<Translate alias="Board" word="Team Table" />
						</div>
					</div>
				</div>
				<div className="blur">
					<div className="object-container-div">
						<div className="container-text">
							<Translate alias="Board" word="Containers" />
						</div>
						<MainDraggableContainer canEdit={canEdit} />
					</div>
					<div className="kits-separator" />

					<div className="object-coins-div">
						<div className="container-text">
							<Translate alias="Board" word="Cash" />
							<div className="object-value">
								<span className="notranslate">
									{state.coins}
								</span>
							</div>
						</div>
						<Row noGutters={true}>
							<Col>
								<MainDraggableCoin
									canEdit={canEdit}
									value={10}
								/>
							</Col>
							<Col>
								<MainDraggableCoin
									canEdit={canEdit}
									value={1}
								/>
							</Col>
							<Col>
								<MainDraggableCoin
									canEdit={canEdit}
									value={0.5}
								/>
							</Col>
						</Row>
					</div>
					<div className="kits-separator" />
					<div className="object-labels-div">
						<div className="container-text">
							<Translate alias="Board" word="Location" />
						</div>
						<Row noGutters={true}>
							<Col>
								<MainDraggableLocation
									canEdit={canEdit}
									value={"basic"}
								/>
								<div className="object-value-center">
									<span className="notranslate">
										{state.standardLocation}
									</span>
								</div>
							</Col>
							<Col>
								<MainDraggableLocation
									canEdit={canEdit}
									value={"premium"}
								/>
								<div className="object-value-center">
									<span className="notranslate">
										{state.premiumLocation}
									</span>
								</div>
							</Col>
						</Row>
					</div>
					<div className="kits-separator" />
					<div className="object-labels-div">
						<div className="container-text">
							<Translate alias="Board" word="Equipment" />
						</div>
						<Row noGutters={true}>
							<Col>
								<MainDraggableEquipment
									canEdit={canEdit}
									value={"basic"}
								/>
								<div className="object-value-center">
									<span className="notranslate">
										{state.standardEquipment}
									</span>
								</div>
							</Col>
							<Col>
								<MainDraggableEquipment
									canEdit={canEdit}
									value={"premium"}
								/>
								<div className="object-value-center">
									<span className="notranslate">
										{state.premiumEquipment}
									</span>
								</div>
							</Col>
						</Row>
					</div>
					{/*<div className="object-labels-div">
						<div className="container-text">Time & Motivation</div>
						<Row noGutters={true}>
							<Col>
								<MainDraggableMotivation />
								<div className="object-value-center">
									<span className="notranslate">{ state.motivation }</span>
								</div>
							</Col>
							<Col>
								<MainDraggableTime />
								<div className="object-value-center">
									<span className="notranslate">{ state.time }</span>
								</div>
							</Col>
						</Row>
					</div>*/}
					<div className="kits-separator"></div>
					<div className="object-coins-div">
						<div className="container-text">
							<Translate alias="Board" word="Employees" />
						</div>
						<Row noGutters={true} className="p-2">
							<Col>
								<MainDraggableAvatar
									canEdit={canEdit}
									color={"yellow"}
								/>
								<div className="object-value-center">
									<span className="notranslate">
										{state.goldAvatar}
									</span>
								</div>
							</Col>
							<Col>
								<MainDraggableAvatar
									canEdit={canEdit}
									color={"grey"}
								/>
								<div className="object-value-center">
									<span className="notranslate">
										{state.silverAvatar}
									</span>
								</div>
							</Col>
							<Col>
								<MainDraggableAvatar
									canEdit={canEdit}
									color={"green"}
								/>
								<div className="object-value-center">
									<span className="notranslate">
										{state.greenAvatar}
									</span>
								</div>
							</Col>
							<Col>
								<MainDraggableAvatar
									canEdit={canEdit}
									color={"white"}
								/>
								<div className="object-value-center">
									<span className="notranslate">∞</span>
								</div>
							</Col>
						</Row>
					</div>
					{state.tokens > 0 && (
						<>
							<div className="kits-separator" />
							<div className="object-container-div">
								<div className="container-text">
									<Translate alias="Board" word="Liability" />{" "}
								</div>
								<MainDraggableLiability
									canEdit={canEdit}
									value={state.tokens ? state.tokens : 0}
								/>
							</div>
						</>
					)}
					<div className="kits-separator" />
					<div className="object-pins-div">
						<div className="container-text">
							<Translate alias="Board" word="Pins" />
							<div className="object-value">
								<span className="notranslate">
									{state.pins}
								</span>
							</div>
						</div>
						<div className="draggable-pins-container">
							{state.pins > 0 ? (
								<Row>
									{[...Array(state.pins)].map((e, i) => (
										<React.Fragment key={i}>
											{i % state.pins === 0 ? (
												<div className="w-100"></div>
											) : null}
											<MainDraggablePin
												canEdit={canEdit}
												key={i}
												count={state.pins}
											/>
										</React.Fragment>
									))}
								</Row>
							) : null}
						</div>
					</div>
					<div className="kits-separator" />
					<div className="object-delete-div">
						<div className="container-text">
							<Translate alias="Board" word="Delete" />
						</div>
						<MainDroppableDelete
							canEdit={canEdit}
							dispatch={dispatch}
						/>
						<div className="clearfix"></div>
					</div>
					<div className="kits-separator"></div>
					<div style={{ height: "30px" }}></div>
				</div>
			</div>
		</>
	);
}
