const urls = {
	backendURL: process.env.REACT_APP_BACKEND_URL,
	socketEndpoint: process.env.REACT_APP_SOCKET_URL,
	authenticate: process.env.REACT_APP_BACKEND_URL + "/api/player/login",
	logout: process.env.REACT_APP_BACKEND_URL + "/api/authenticate",
	teamDetails: process.env.REACT_APP_BACKEND_URL + "/api/team-details",
	renameTeam: process.env.REACT_APP_BACKEND_URL + "/api/team/rename",
	activeCounter: process.env.REACT_APP_BACKEND_URL + "/api/counter/active",
	checklist: process.env.REACT_APP_BACKEND_URL + "/checklist-group-all",
	eventLink:
		process.env.REACT_APP_BACKEND_URL + "/api/managing3/active/event",
	checklist:
		process.env.REACT_APP_BACKEND_URL + "/managing3/checklist-group-all",
};

export default urls;
