import React from "react";

import {
	Container,
	Row,
	Col,
	Tabs,
	Tab,
	Dropdown,
	DropdownButton,
	ButtonGroup,
} from "react-bootstrap";

import "../../assets/css/Forms.css";

// Components;
import IncomeStatement from "./Partials/IncomeStatement";
import BalanceSheet from "./Partials/BalanceSheet";
import CashFlow from "./Partials/CashFlow";
import RealtimeConnection from "../../utils/RealtimeConnection";
import Translate from "../../utils/Translate";

export default function AnnualReport({
	canEdit,
	dispatch,
	annualReport,
	session,
}) {
	function closeAnnual(e) {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		dispatch({ type: "annualReport", value: false });
	}

	function changeFormYear(value) {
		RealtimeConnection.changeFormYear({
			year: value,
			type: "annualReport",
		});
	}
	let yearsOptions = [];
	for (let i = 0; i <= session.year; i++) {
		yearsOptions.push(
			<Dropdown.Item
				key={i}
				eventKey={i}
				onClick={() => changeFormYear(i)}
			>
				<Translate alias="Board" word="Year" /> {i}
			</Dropdown.Item>
		);
	}

	let formStatus = canEdit ? true : false;
	if (session.formSelectedYear !== session.year) {
		formStatus = false;
	}

	return (
		<Container className="formContainer">
			<Row>
				<Col>
					<div className="formHeader">
						<div className="headerTitle bold">
							<Translate alias="Forms" word="Annual Report" />
						</div>
						<DropdownButton
							as={ButtonGroup}
							variant="outline-secondary"
							title={ <Translate alias="Board" word={ "Year" } append={ session.formSelectedYear } /> }
							size="sm"
							style={{ marginLeft: "10px" }}
						>
							{yearsOptions}
						</DropdownButton>
						<div className="closeForm" onClick={closeAnnual}>
							X
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col>
					<Tabs
						defaultActiveKey="incomeStatement"
						id="uncontrolled-tab-example"
					>
						<Tab
							eventKey="incomeStatement"
							title={<Translate alias="Forms" word="P&L" />}
						>
							<IncomeStatement
								canEdit={formStatus}
								incomeStatement={annualReport.incomeStatement}
								year={session.formSelectedYear}
							/>
						</Tab>
						<Tab
							eventKey="balanceSheet"
							title={
								<Translate alias="Forms" word="Balance Sheet" />
							}
						>
							<BalanceSheet
								canEdit={formStatus}
								balanceSheet={annualReport.balanceSheet}
								year={session.formSelectedYear}
							/>
						</Tab>
						<Tab
							eventKey="cashFlow"
							title={<Translate alias="Forms" word="Cash Flow" />}
						>
							<CashFlow
								canEdit={formStatus}
								cashFlow={annualReport.cashFlow}
								year={session.formSelectedYear}
							/>
						</Tab>
					</Tabs>
				</Col>
			</Row>
		</Container>
	);
}
