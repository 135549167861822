import React from "react";
import { useDrop } from "react-dnd";

// Images
import deleteImg from "../../assets/images/delete.png";
import deleteActive from "../../assets/images/delete-active.png";

// Utils
import items from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

export default function MainDraggableDelete({ canEdit, dispatch }) {
	const [{ isOver }, drop] = useDrop({
		accept: [items.container, items.coin, items.green, items.grey, items.yellow, items.white, items.token, items.pin, items.location, items.equipment],
		collect: (monitor) => ({
			isOver: monitor.isOver(),
		}),
		drop: (item) => {
			if (item.type === items.container) {
				if (item.coins.length > 0) {
					let coinsValue = 0
					item.coins.forEach( coin => coinsValue += coin)

					RealtimeConnection.increaseTeamTable({
						type: "coins",
						value: coinsValue
					})
				}
				return { success: true }
			} else if (item.type === items.green || item.type === items.grey || item.type === items.yellow || item.type === items.white) {
				let avatarType = ""
				if(item.type === items.yellow) {
					avatarType = "goldAvatar"
				}else if(item.type === items.grey) {
					avatarType = "silverAvatar"
				}else if(item.type === items.green) {
					avatarType = "greenAvatar"
				}
				if (avatarType !== "") {
					RealtimeConnection.increaseTeamTable({
						type: avatarType,
						value: 1
					})
				}

				return { success: true }
				//dispatch({ type: "deleteAvatar", color: item.color, regionIndex: item.regionIndex });
			} else if (item.type === items.token) {
				RealtimeConnection.increaseTeamTable({
					type: "tokens",
					value: item.value
				})
				return { success: true }
			} else if (item.type === items.pin) {
				RealtimeConnection.increaseTeamTable({
					type: "pins",
					value: 1
				})
				return { success: true }
			} else if (item.type === items.coin) {
				RealtimeConnection.increaseTeamTable({
					type: "coins",
					value: item.value
				})
				return { success: true }
			}else if (item.type === items.location) {
				RealtimeConnection.increaseTeamTable({
					type: (item.value === "basic") ? "standardLocation" : "premiumLocation",
					value: 1
				})
				return { success: true }
			}else if (item.type === items.equipment) {
				RealtimeConnection.increaseTeamTable({
					type: (item.value === "basic") ? "standardEquipment" : "premiumEquipment",
					value: 1
				})
				return { success: true }
			}
		},
	});

	const canDrop = (canEdit) ? drop : null
	return (
		<div className="removeDroppable" ref={canDrop}>
			<img alt="drop" src={isOver ? deleteActive : deleteImg} />
		</div>
	);
}
