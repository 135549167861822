import React, { useState, useEffect } from "react"

// Helpers
import RealtimeConnection from "../../../utils/RealtimeConnection";

const _InputField = ({ year, value, alias, index, itemIndex, itemFieldIndex }) => {

	const [fieldValue, setFieldValue] = useState(undefined)

	useEffect(() => {
		if (value !== fieldValue) {
			setFieldValue(value)
		}
	}, [value, fieldValue])

	const inputChanged = (value) => {
		RealtimeConnection.changePlanningSheet({
			type: "planningSheet",
			year: year,
			alias: alias,
			parentIndex: index,
			value: value,
			fieldIndex: itemIndex,
			nestedIndex: itemFieldIndex
		});
	}

	return (
		<input
			type="number"
			className="input-number-planning"
			value={fieldValue}
			onChange={(e) => {
				setFieldValue(e.target.value ? e.target.value : 0)
				inputChanged(parseFloat(e.target.value ? e.target.value : 0))
			}}
			// disabled={!this.props.canEdit}
			decimalSeparator={","}
		/>
	)
}

export default _InputField;