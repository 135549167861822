import React, { useEffect, useState } from "react";
import { Table, Row, Col, Container, Button } from "reactstrap";

// Images
import container from "../../assets/images/container-h.png";
import containerFlipped from "../../assets/images/box-container-flipped.png";
import previewGoldCoinImg from "../../assets/images/dollar_coin-preview.png";
import previewSilverCoinImg from "../../assets/images/dollar_coin_silver-preview.png";
import previewBronzeCoinImg from "../../assets/images/dollar_coin_bronze-preview.png";

// Utils
import RealtimeConnection from "../../utils/RealtimeConnection";

// CSS
import "../../assets/css/ContainerModal.css";
import Translate from "../../utils/Translate";

const formatNumber = new Intl.NumberFormat("en-US");
export default function ContainerModal({
	canEdit,
	toggle,
	cashCoinsCount,
	depreciation,
	alias,
	fieldIndex,
	regionIndex,
	coins,
	coinsCount,
	location,
	rented,
	equipment
}) {
	const [showAddCashQuestion, setShowAddCashQuestion] = useState(false);
	const [showSubtractCashQuestion, setShowSubtractCashQuestion] =
		useState(false);
	const [cashValueInput, setCashValueInput] = useState("");
	const [msg, setMsg] = useState(false);
	const [err, setErr] = useState("");

	useEffect(() => {
		if (showAddCashQuestion) {
			setShowSubtractCashQuestion(false);
		}
	}, [showAddCashQuestion]);
	useEffect(() => {
		if (showSubtractCashQuestion) {
			setShowAddCashQuestion(false);
		}
	}, [showSubtractCashQuestion]);

	const getCoinRow = (key, imgSrc, count, value) => {
		return (
			<tr key={key}>
				<td>
					<img src={imgSrc} className="prevCoinImg" alt="" />
				</td>
				<td>{count}</td>
				<td>{formatNumber.format(parseFloat(value) * count)}</td>
			</tr>
		);
	};

	const addCoinsAction = (currentCoins) => {
		const addValue = cashValueInput.replace(",", ".")
		let cashValue = parseFloat(addValue);
		cashValue = Math.ceil(cashValue * 2) / 2;

		const totalCash = cashCoinsCount ? cashCoinsCount.reduce((a, b) => a + b, 0) : 0;
		const cash = depreciation ? coinsCount : totalCash;
		if (cash >= cashValue) {
			setErr("");
			let currentCoinsValue = 0;
			for (let i = 0; i < currentCoins.length; i++) {
				currentCoinsValue += currentCoins[i];
			}

			let newCashValue = currentCoinsValue + cashValue;
			let newCoins = [];
			while (newCashValue > 0) {
				if (newCashValue >= 10) {
					newCoins.push(10);
					newCashValue -= 10;
				} else if (newCashValue >= 1) {
					newCoins.push(1);
					newCashValue -= 1;
				} else {
					newCoins.push(0.5);
					newCashValue -= 0.5;
				}
			}

			if (newCoins.length > 0) {
				if (newCoins.length <= 20) {
					setShowAddCashQuestion(false);

					RealtimeConnection.addContainer({
						alias: alias,
						name: fieldIndex,
						regionIndex: regionIndex,
						coins: newCoins,
					});
					if (depreciation) {
						RealtimeConnection.decreaseTeamTable({
							type: "coins",
							value: cashValue,
						});
					} else {
						RealtimeConnection.decreaseCash({
							type: "DecreaseCash",
							value: cashValue,
						});
					}

					toggle();
				}
			}
		} else {
			if (depreciation) {
				setErr(
					"Don't take out of cash. Put coins from location/equipment to team table, then take it from there"
				);
			} else {
				setErr("Not Enough Cash");
			}
		}
	};

	const subtractCoinsAction = (currentCoins) => {
		const subValue = cashValueInput.replace(",", ".")
		if (currentCoins.length > 0 && parseFloat(subValue) > 0) {
			let cashValue = parseFloat(subValue);
			cashValue = Math.ceil(cashValue * 2) / 2;

			const coinsValue = cashValue;

			let currentCoinsValue = 0;
			for (let i = 0; i < currentCoins.length; i++) {
				currentCoinsValue += currentCoins[i];
			}

			if (currentCoinsValue >= cashValue) {
				let newCashValue = currentCoinsValue - cashValue;
				let newCoins = [];
				while (newCashValue > 0) {
					if (newCashValue >= 10) {
						newCoins.push(10);
						newCashValue -= 10;
					} else if (newCashValue >= 1) {
						newCoins.push(1);
						newCashValue -= 1;
					} else {
						newCoins.push(0.5);
						newCashValue -= 0.5;
					}
				}

				if (newCoins.length <= 20) {
					setShowSubtractCashQuestion(false);

					// Update Socket
					RealtimeConnection.addContainer({
						alias: alias,
						name: fieldIndex,
						regionIndex: regionIndex,
						coins: newCoins,
					});
					if (depreciation || location || equipment) {
						RealtimeConnection.increaseTeamTable({
							type: "coins",
							value: coinsValue,
						});
					} else {
						RealtimeConnection.increaseCash({
							type: "IncreaseCash",
							value: coinsValue,
						});
					}

					toggle();
				}
			}
		}
	};

	const changeLocationStatus = (status) => {
		RealtimeConnection.changeLocationStatus({
			regionIndex: regionIndex,
			coins: coins,
			status: status,
		});
		if (status === "Rent") {
			let sum = coins.reduce((a, b) => a + b, 0);
			RealtimeConnection.increaseTeamTable({
				type: "coins",
				value: sum,
			});
		}
	};
	let coinsList = {};
	coins.forEach((value) => {
		coinsList[`${value}`] = coinsList[`${value}`]
			? coinsList[`${value}`] + 1
			: 1;
	});

	let coinsRows = [];
	let totalCoinsValue = 0;
	let totalCoinsCount = 0;

	if (coinsList[`10`]) {
		totalCoinsValue += 10 * parseFloat(coinsList[`10`]);
		totalCoinsCount += parseFloat(coinsList[`10`]);

		coinsRows.push(getCoinRow(0, previewGoldCoinImg, coinsList[`10`], 10));
	}
	if (coinsList[`1`]) {
		totalCoinsValue += 1 * parseFloat(coinsList[`1`]);
		totalCoinsCount += parseFloat(coinsList[`1`]);

		coinsRows.push(getCoinRow(1, previewSilverCoinImg, coinsList[`1`], 1));
	}
	if (coinsList[`0.5`]) {
		totalCoinsValue += 0.5 * parseFloat(coinsList[`0.5`]);
		totalCoinsCount += parseFloat(coinsList[`0.5`]);

		coinsRows.push(
			getCoinRow(2, previewBronzeCoinImg, coinsList[`0.5`], 0.5)
		);
	}
	return (
		<>
			<div className="containerObjClass">
				{rented ? (
					<img
						className="objModelClass"
						src={containerFlipped}
						alt=""
					/>
				) : (
					<img className="objModelClass" src={container} alt="" />
				)}

				{location && (
					<Container style={{ margin: "0", width: "30%" }}>
						{rented ? (
							<Row>
								<Col>
									<Button
										className="purchase-btn"
										onClick={() => {
											changeLocationStatus("Purchase");
											setMsg(true);
										}}
										size="sm"
									>
										<Translate
											alias="Board"
											word="Purchase"
										/>
									</Button>
								</Col>
							</Row>
						) : (
							<Row style={{ marginTop: "5px" }}>
								<Col>
									<Button
										className="rent-btn"
										onClick={() => {
											changeLocationStatus("Rent");
											setMsg(false);
										}}
										size="sm"
									>
										<Translate alias="Board" word="Rent" />
									</Button>
								</Col>
							</Row>
						)}
					</Container>
				)}

				<div className="coins-container">
					<div className="helper"></div>
					{coins.map((value, index) => {
						const isLastCoin =
							coins.length === index + 1 && (index + 1) % 5 === 0
								? true
								: false;
						let coinClass = isLastCoin
							? "last-coin "
							: "not-last-coin ";
						switch (value) {
							case 10:
								coinClass += "gold";
								break;
							case 1:
								coinClass += "silver";
								break;
							case 0.5:
								coinClass += "bronze";
								break;
							default:
								break;
						}
						return (
							<div className={["coin", coinClass].join(" ")} />
						);
					})}
				</div>
			</div>
			<div className="clearfix"></div>
			{msg && (
				<div style={{ textAlign: "center", color: "red" }}>
					<Translate
						alias="Board"
						word="Make sure to make an investment"
					/>
				</div>
			)}
			<div style={{ height: 20 }}></div>
			<Table striped bordered>
				<thead>
					<tr style={{ color: "white", backgroundColor: "#002955" }}>
						<th>
							<Translate alias="Board" word="Value" />
						</th>
						<th>
							<Translate alias="Board" word="Count" />
						</th>
						<th>
							<Translate alias="Board" word="Cash" />
						</th>
					</tr>
				</thead>
				<tbody>{coinsRows}</tbody>
				<tbody>
					<tr>
						<td className="bold-text">
							<Translate alias="Board" word="Total" />
						</td>
						<td className="bold-text">{totalCoinsCount}</td>
						<td className="bold-text">
							{formatNumber.format(totalCoinsValue)}
						</td>
					</tr>
				</tbody>
			</Table>
			<div className="horizontal-separator">&nbsp;</div>
			{!rented && canEdit && (
				<div className="container-actions">
					<Container>
						<Row>
							<Col xs={1}></Col>
							<Col>
								<Button
									className="purchase-btn"
									onClick={() => setShowAddCashQuestion(true)}
									size="sm"
								>
									<Translate alias="Board" word="Add" />
								</Button>
							</Col>
							<Col xs={2}></Col>
							<Col>
								<Button
									className="rent-btn"
									onClick={() =>
										setShowSubtractCashQuestion(true)
									}
									size="sm"
								>
									<Translate alias="Board" word="Subtract" />
								</Button>
							</Col>
							<Col xs={1}></Col>
						</Row>
						<Row>
							<Col className="questions-container">
								{showAddCashQuestion ? (
									<>
										<div
											style={{
												textAlign: "center",
												color: "red",
											}}
										>
											{err && err}
										</div>
										<div className="question-text">
											<Translate
												alias="Board"
												word="How much cash do you want to add?"
											/>
										</div>
										<div className="question-input">
											<input
												type="text"
												autoFocus
												id="units"
												pattern="^-?[0-9]\d*\.?\d*$"
												name="units"
												onKeyPress={(event) => {
													if (event.key === "Enter") {
														addCoinsAction(coins);
													}
												}}
												onChange={(e) =>
													setCashValueInput(
														e.target.value
													)
												}
											/>
										</div>
										<div className="question-btn">
											<Button
												className="purchase-btn"
												size="sm"
												onClick={() =>
													addCoinsAction(coins)
												}
											>
												<Translate
													alias="Board"
													word="Confirm"
												/>
											</Button>
										</div>
									</>
								) : null}
								{showSubtractCashQuestion ? (
									<>
										<div className="question-text">
											<Translate
												alias="Board"
												word="How much cash do you want to
											subtract?"
											/>
										</div>
										<div className="question-input">
											<input
												type="text"
												autoFocus
												id="units2"
												pattern="^-?[0-9]\d*\.?\d*$"
												name="units2"
												onKeyPress={(event) => {
													if (event.key === "Enter") {
														subtractCoinsAction(
															coins
														);
													}
												}}
												onChange={(e) =>
													setCashValueInput(
														e.target.value
													)
												}
											/>
										</div>
										<div className="question-btn">
											<Button
												className="purchase-btn"
												size="sm"
												onClick={() =>
													subtractCoinsAction(coins)
												}
											>
												<Translate
													alias="Board"
													word="Confirm"
												/>
											</Button>
										</div>
									</>
								) : null}
							</Col>
						</Row>
					</Container>
				</div>
			)}
		</>
	);
}
