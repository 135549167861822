import React from "react";
import { Col, Row } from "reactstrap";
import director from "../../assets/images/avatar-1.png";
import manager from "../../assets/images/avatar-2.png";
import employee from "../../assets/images/avatar.png";
import intern from "../../assets/images/white-avatar.png";

export default function MarketRegion(props) {
	return (
		<Col className={`market-region ${props.class} p-2`}>
			<div>
				<p className="text-left">{`Region ${props.id}`}</p>
			</div>

			<Row noGutters={true} xs={2}>
				<Col className="labor-market-wrapper">
					{props.data.gold > 0 ? (
						<>
							<div
								style={{
									display: "flex",
									flexWrap: "wrap",
									justifyContent: "center",
								}}
							>
								{[
									...Array(
										props.data.gold < 10
											? props.data.gold
											: 10
									),
								].map((_e, index) => {
									return (
										<div className="avatar-labor-market-container">
											<img
												key={index}
												alt="manager"
												className="character-labor-market"
												src={manager}
											/>
										</div>
									);
								})}
							</div>
							{props.data.gold > 10 && (
								<div className="object-value-center">
									<span className="notranslate">
										+{props.data.gold - 10}
									</span>
								</div>
							)}
						</>
					) : (
						<>
							<img
								alt="manager"
								className="market-avatar"
								src={manager}
							/>
							<div className="object-value-center">
								<span className="notranslate">
									{props.data.gold}
								</span>
							</div>
						</>
					)}
				</Col>
				<Col className="labor-market-wrapper">
					{props.data.silver > 0 ? (
						<>
							<div style={{ display: "flex", flexWrap: "wrap" }}>
								{[
									...Array(
										props.data.silver < 10
											? props.data.silver
											: 10
									),
								].map((_e, index) => {
									return (
										<div className="avatar-labor-market-container">
											<img
												key={index}
												alt="director"
												className="character-labor-market"
												src={director}
											/>
										</div>
									);
								})}
							</div>
							{props.data.silver > 10 && (
								<div className="object-value-center">
									<span className="notranslate">
										+{props.data.silver - 10}
									</span>
								</div>
							)}
						</>
					) : (
						<>
							<img
								alt="director"
								className="market-avatar"
								src={director}
							/>
							<div className="object-value-center">
								<span className="notranslate">
									{props.data.silver}
								</span>
							</div>
						</>
					)}
				</Col>
				<Col className="labor-market-wrapper">
					{props.data.green > 0 ? (
						<>
							<div style={{ display: "flex", flexWrap: "wrap" }}>
								{[
									...Array(
										props.data.green < 10
											? props.data.green
											: 10
									),
								].map((_e, index) => {
									return (
										<div className="avatar-labor-market-container">
											<img
												key={index}
												alt="employee"
												className="character-labor-market"
												src={employee}
											/>
										</div>
									);
								})}
							</div>
							{props.data.green > 10 && (
								<div className="object-value-center">
									<span className="notranslate">
										+{props.data.green - 10}
									</span>
								</div>
							)}
						</>
					) : (
						<>
							<img
								alt="employee"
								className="market-avatar"
								src={employee}
							/>
							<div className="object-value-center">
								<span className="notranslate">
									{props.data.green}
								</span>
							</div>
						</>
					)}
				</Col>
				<Col className="labor-market-wrapper">
					<img alt="intern" className="market-avatar" src={intern} />
					<div className="object-value-center">
						<span className="notranslate">∞</span>
					</div>
				</Col>
			</Row>
		</Col>
	);
}
