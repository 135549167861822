import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "reactstrap";

// CSS
import "../../assets/css/EventModal.css";

// Images
import YellowFace from "../../assets/images/faceYellow.svg";
import GreenFace from "../../assets/images/faceGreen.svg";
import WhiteFace from "../../assets/images/faceWhite.svg";
import BlackFace from "../../assets/images/faceBlack.svg";


import urls from "../../utils/urls";
import Translate from "../../utils/Translate";


export default function CommonEventPopup({ eventId }) {
	const [eventData, setEventData] = useState(undefined);

	let icon = YellowFace;
	if (eventData) {
		if (eventData.icon === "faceYellow") {
			icon = YellowFace;
		} else if (eventData.icon === "faceGreen") {
			icon = GreenFace;
		} else if (eventData.icon === "faceWhite") {
			icon = WhiteFace;
		} else if (eventData.icon === "faceBlack") {
			icon = BlackFace;
		}
	}
	const fetchEvent = async (id) => {
		const result = await axios.post(
			urls.eventLink,
			{
				playerId: !isNaN(window.playerId) ? window.playerId : 0,
				adminId: !isNaN(window.adminId) ? window.adminId : 0,
				teamId: window.teamId,
				token: window.token,
				sessionId: window.sessionId,
				languageId: localStorage.langId,
			},
			{ headers: { "content-type": "application/json" } }
		);

		console.log(result);

		if (result.data.success) {
			setEventData(result.data.event);
		} else {
		}
	};
	useEffect(() => {
		if (eventId) {
			fetchEvent(eventId);
		}
	}, [eventId]);
	return (
		<Modal className="CommonEventPopup" isOpen={true}>
			{eventData && (
				<div className="content">
					<div className="header-container">
						<img src={icon} alt="" />
						<h3>{eventData.title}</h3>
					</div>
					<div className="content-body ">
						<p className="mb-5">{eventData.description}</p>
						<div className="impact-section ">
							<div className="impact-row">
								<div className="impact-title"><Translate alias="Forms" word="Gold" /></div>
								<div className="impact-value">
									{eventData.gold}
								</div>
							</div>
							<div className="impact-row">
								<div className="impact-title"><Translate alias="Forms" word="Silver" /></div>
								<div className="impact-value">
									{eventData.silver}
								</div>
							</div>
							<div className="impact-row">
								<div className="impact-title"><Translate alias="Forms" word="Green" /></div>
								<div className="impact-value">
									{eventData.green}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</Modal>
	);
}
